import React, { FC, useState } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Zoom from "@mui/material/Zoom";

import { Job, JobStatus } from "stores/managerService/jobStore/types";

import { useRootStore } from "hooks/useRootStore";

import DeletePopupForm from "../DeletePopupForm";

interface ActionButtonProps {
  job: Job;
}

const ActionButtons: FC<ActionButtonProps> = ({ job }) => {
  const { jobStore, uiStore } = useRootStore();
  const { createMode, editMode } = jobStore;

  const [actionButtons, setActionButtons] = useState("");
  const [scrollTop, setScrollTop] = useState(0);

  const changeBoundaries = async (jobId: string, map: string) => {
    setActionButtons("");
    jobStore.setEditMode(true);
    await jobStore.changeBoundaries(jobId, map);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <IconButton
        sx={createMode || editMode ? { ...iconStyles, pointerEvents: "none", opacity: 0.5 } : iconStyles}
        onClick={() => {
          setActionButtons(job.id);
          setScrollTop(document.getElementById("jobs")!.scrollTop);
        }}>
        <MoreHorizIcon fontSize="small"/>
      </IconButton>
      <Box sx={buttonsWrapperStyles(actionButtons === job.id, scrollTop)} onMouseLeave={() => setActionButtons("")}>
        <Zoom in={actionButtons === job.id}>
          <Fab
            size="small"
            aria-label="edit"
            sx={{ mb: 1, color: "background.default" }}
            onClick={async () => await changeBoundaries(job.id, job.map)}
            disabled={job.status === JobStatus.CREATE_FAILED}>
            <MyLocationIcon />
          </Fab>
        </Zoom>
        <Zoom in={actionButtons === job.id} style={{ transitionDelay: "100ms" }}>
          <Fab size="small" aria-label="delete" sx={{ color: "background.default" }}>
            <DeleteOutlinedIcon
              onClick={() => uiStore.openModal(<DeletePopupForm jobId={job.id} areaId={job.area} />)}
            />
          </Fab>
        </Zoom>
      </Box>
    </Box>
  );
};

const iconStyles = {
  color: "text.primary",
  bgcolor: "background.default",
  "& .MuiSvgIcon-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    bgcolor: "background.default",
    "& .MuiSvgIcon-root": {
      color: "primary.main",
    },
  },
};

const buttonsWrapperStyles = (selected: boolean, scrollTop: number) => {
  return {
    pl: selected ? 4 : 0,
    ml: selected ? 0 : 4,
    zIndex: selected ? 10 : -1,
    transition: "z-index 0.24s",
    mt: `calc(-64px - ${scrollTop}px)`,
    position: "fixed",
    display: "flex",
    flexDirection: "column",
  } as const;
};

export default ActionButtons;
