import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { Divider, Fade, Paper, Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import { IFile } from "stores/fileStore/types";

import { useFetchWithLoading } from "hooks";
import { useRootStore } from "hooks/useRootStore";

import AttachmentsItemActions from "./AttachmentsItemActions";
import AttachmentsItemInfo from "./AttachmentsItemInfo";
import { formatBytes } from "../../../helpers/functions";

interface AttachmentsItemProps {
  file: IFile;
  foreignId: string;
  acl: string;
  getAttachments: () => void;
  disabledToDelete: boolean;
}

const AttachmentsItem: FC<AttachmentsItemProps> = ({ file, foreignId, acl, getAttachments, disabledToDelete }) => {
  const { t } = useTranslation();
  const { fileStore } = useRootStore();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleOpenFile = async () => {
    handleClose();
    await fileStore.open(file.subsystem, file.storageProvider, foreignId, acl, file.storageMeta.key);
  };

  const [fetchWithLoading, isLoadingInProgress] = useFetchWithLoading(() => fileStore.delete(foreignId, file.id));
  const handleDeleteFile = async () => {
    handleClose();
    await fetchWithLoading();
    getAttachments();
  };

  const disabled = !file.storageMeta.key || isLoadingInProgress;
  const ADDITIONAL_TEXT_COLOR = "#ADADAD";

  return (
    <Fade in key={file.id}>
      <Paper sx={itemWrapperStyles} elevation={0}>
        <Box>
          <AttachmentsItemInfo file={file} />
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="caption" fontWeight={300} sx={{ mr: 1, color: ADDITIONAL_TEXT_COLOR }}>
            {formatBytes(file.fileMeta.size)}
          </Typography>
          <Divider orientation="vertical" flexItem sx={{ borderColor: "secondary.main" }} />
          <AttachmentsItemActions
            anchorEl={anchorEl}
            open={open}
            handleMenuButtonClick={handleMenuButtonClick}
            handleClose={handleClose}
            disabled={disabled}
            actions={[
              {
                func: handleOpenFile,
                icon: <OpenInNewRoundedIcon fontSize="small" />,
                title: t("General:Open"),
              },
              {
                func: handleDeleteFile,
                icon: <DeleteOutlineRoundedIcon fontSize="small" />,
                title: t("General:Delete"),
                disabled: disabledToDelete,
              },
            ]}
          />
        </Box>
      </Paper>
    </Fade>
  );
};

const itemWrapperStyles = {
  p: "8px",
  bgcolor: "transparent",
  border: "1px solid",
  borderRadius: "5px",
  borderColor: "secondary.main",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  mb: 1,
  "&:last-of-type": { mb: 0 },
};

export default observer(AttachmentsItem);
