import { ApiResponse } from "types";

export enum CredentialType {
  PIX4D = "PIX4D",
}

export interface Credentials {
  id: string;
  name: string;
  clientId: string;
  clientSecret: string;
  type: CredentialType;
  createdAt: string;
  updatedAt: string;
  ownerId: string;
}

export interface CredentialsRequest {
  name: string;
  clientId: string;
  clientSecret: string;
  type: CredentialType;
}

export type CredentialsResponse = ApiResponse<Credentials[]>;

export type CreateCredentialResponse = ApiResponse<Credentials>;
