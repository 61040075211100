import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { onboardingStore } from "stores/onboardingStore";

import { ItemsList, ListItem } from "components/shared/ItemsList";
import DateAndUserItemInfoContent from "components/shared/InfoList/DateAndUserItemInfoContent";
import { useRootStore } from "hooks/useRootStore";
import { absolutePath } from "router/paths";

import ActionButtons from "./ActionButtons";

const List: FC = () => {
  const navigate = useNavigate();

  const {
    areaStore: { createMode, editMode, filteredAreas, area },
    mapStore: { clearAreaAndNested },
    teamStore: { csPermissions },
  } = useRootStore();

  const { isOnboardingRunning } = onboardingStore;

  const handleSelectArea = useCallback(
    async (areaId: string) => {
      if (areaId === area?.id) return;

      clearAreaAndNested();
      navigate(absolutePath(`area/${areaId}`));

      if (isOnboardingRunning) {
        onboardingStore.nextStep();
      }
    },
    [area?.id, clearAreaAndNested, isOnboardingRunning, navigate]
  );

  return (
    <ItemsList id={"areas"}>
      {filteredAreas.map(a => (
        <ListItem
          key={a.id}
          itemId={a.id}
          title={a.title}
          handleSelect={handleSelectArea}
          isCreateOrEditMode={createMode || editMode}
          actionButtonsSlot={csPermissions.creating && <ActionButtons area={a} />}
          infoContent={<DateAndUserItemInfoContent userId={a.createdBy} date={new Date(a.createdAt)} />}
        />
      ))}
    </ItemsList>
  );
};

export default observer(List);
