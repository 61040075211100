import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";

import { DisplayOptions } from "components/shared/DisplayOptions";
import { NoDataMessage } from "components/shared/NoDataMessage";
import { useRootStore } from "hooks/useRootStore";

import CreateButton from "./CreateButton";
import CreateMode from "./CreateMode";
import EditMode from "./EditMode";
import List from "./List";
import { Loader } from "components/shared/Loader";
interface JobProps {
  areaId: string;
  isAreaLoadingInProgress: boolean;
}

const Job: FC<JobProps> = ({ areaId, isAreaLoadingInProgress }) => {
  const { t } = useTranslation();
  const { jobStore } = useRootStore();
  const { jobs, createMode, editMode } = jobStore;

  const isUpdatingInProcess = createMode || editMode;
  return (
    <Box sx={containerStyles}>
      {createMode && <CreateMode areaId={areaId} />}
      {editMode && <EditMode areaId={areaId} />}
      {!isUpdatingInProcess && (
        <DisplayOptions>
          <CreateButton />
        </DisplayOptions>
      )}
      <>{isAreaLoadingInProgress ? <Loader /> : jobs.length ? <List /> : <NoDataMessage message={t("Job:NoJobs")} />}</>
    </Box>
  );
};

const containerStyles = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  mt: 1,
} as const;

export default observer(Job);
