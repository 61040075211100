import { Container, FormControl, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { DateRange } from "components/Metrics";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const quarters = [
  "1st Quarter (Jan - Mar)",
  "2nd Quarter (Apr - Jun)",
  "3rd Quarter (Jul - Sep)",
  "4th Quarter (Oct - Dec)",
];

interface QuarterSelect {
  setStartAndEndDateInMilliseconds: ({ fromDate, toDate }: DateRange) => void;
}

export const QuarterSelect: React.FC<QuarterSelect> = ({ setStartAndEndDateInMilliseconds }) => {
  const currentYear = new Date().getFullYear();
  const [selectedQuarter, setSelectedQuarter] = useState<number>(Math.floor(new Date().getMonth() / 3) + 1);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const { t } = useTranslation();
  const handleQuarterChange = (event: SelectChangeEvent<`${number}-${number}`>) => {
    const [quarter, year] = (event.target.value as string).split("-").map(Number);
    setSelectedQuarter(quarter);
    setSelectedYear(year);

    const startMonth = (quarter - 1) * 3;
    const endDate = new Date(year, quarter * 3, 0);

    const startDate = new Date(year, startMonth, 1);
    setStartAndEndDateInMilliseconds({ fromDate: startDate.getTime(), toDate: endDate.getTime() });
  };

  const handleSelectYear = (event: SelectChangeEvent<number>) => {
    setSelectedYear(event.target.value as number);
    const startMonth = (selectedQuarter - 1) * 3;
    const endDate = new Date(event.target.value as number, selectedQuarter * 3, 0);
    const startDate = new Date(event.target.value as number, startMonth, 1);
    setStartAndEndDateInMilliseconds({ fromDate: startDate.getTime(), toDate: endDate.getTime() });
  };

  return (
    <FormControl variant="outlined" size="small" sx={{ paddingLeft: "11px" }}>
      <Select
        sx={{
          fontSize: 14,
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "#ADADAD" },
        }}
        value={`${selectedQuarter}-${selectedYear}`}
        onChange={handleQuarterChange}
        displayEmpty
        renderValue={selected => {
          const [quarter, year] = (selected as string).split("-").map(Number);
          return `${quarters[quarter - 1]}`;
        }}>
        {quarters.map((quarterName, index) => (
          <MenuItem sx={{ fontSize: 14 }} key={quarterName} value={`${index + 1}-${selectedYear}`}>
            {`${t(`Metrics:${quarterName}`)}`}
          </MenuItem>
        ))}
        <MenuItem>
          <Select
            sx={{ fontSize: 14, "& .MuiOutlinedInput-notchedOutline": { borderColor: "#ADADAD" } }}
            value={selectedYear}
            onChange={handleSelectYear}
            size="small">
            {Array.from({ length: 3 }).map((_, index) => {
              const item = currentYear - index;
              return (
                <MenuItem sx={{ fontSize: 14 }} disabled={item === selectedYear} key={item} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
