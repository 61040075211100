import React, { FC, useEffect, useMemo, useState } from "react";
import { Stack, SxProps, Theme } from "@mui/material";
import { observer } from "mobx-react-lite";

import { MeasurementType } from "stores/measurementStore/types";
import { useRootStore } from "hooks/useRootStore";

import { Loader } from "components/shared/Loader";
import { NoDataMessage } from "components/shared/NoDataMessage";
import { thumbStyles } from "constants/styles";

import MeasurementItem from "./MeasurementItem";
import { Id } from "../types";

interface MeasurementsItemsListProps {
  selectedMeasurementType: MeasurementType;
  measurementsForExport: string[];
  handleSelectMeasurementForExport: (value: string) => void;
  exportMode: boolean;
}

const MeasurementsItemsList: FC<Id & MeasurementsItemsListProps> = ({
  id,
  selectedMeasurementType,
  handleSelectMeasurementForExport,
  measurementsForExport,
  exportMode,
}) => {
  const { measurementStore } = useRootStore();
  const { measurements } = measurementStore;

  const measurementsByType = useMemo(
    () => measurements?.filter(({ type }) => type === selectedMeasurementType),
    [measurements, selectedMeasurementType]
  );

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const fetchMeasurements = async () => {
      setLoaded(false);
      await measurementStore.getAllMeasurements(id);
      setLoaded(true);
    };
    void fetchMeasurements();
    return measurementStore.setMeasurements(null);
  }, [id]);

  if (!loaded) {
    return <Loader />;
  }

  return (
    <Stack spacing={1} sx={listContainerStyles}>
      {!!measurementsByType?.length &&
        loaded &&
        measurementsByType.map(m => (
          <MeasurementItem
            key={m.id}
            measurementType={selectedMeasurementType}
            measurement={m}
            handleSelectMeasurementForExport={handleSelectMeasurementForExport}
            exportMode={exportMode}
            selected={measurementsForExport.includes(m.id)}
          />
        ))}
      {!measurementsByType?.length && loaded && <NoDataMessage />}
    </Stack>
  );
};

const listContainerStyles: SxProps<Theme> = {
  maxHeight: "50vh",
  ...thumbStyles,
  pr: 0.5,
};

export default observer(MeasurementsItemsList);
