import React, { createRef, FC, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import {
  IParamsForInitiateProcessing,
  IProcessing,
  Processings,
  ProcessingStatus,
} from "stores/managerService/processingStore/types";
import { onboardingStore } from "stores/onboardingStore";

import Status from "components/shared/Status";
import { StatusName } from "components/shared/Status/types";
import { cardMaxHeightProps, thumbStyles } from "constants/styles";
import { useRootStore } from "hooks/useRootStore";

import ActionButtons from "./ActionButtons";
import { absolutePath } from "../../../../router/paths";

const processingStatusesForDisabling = ["CREATE_IN_PROGRESS", "CREATE_PENDING", "REMOVE_PENDING"];

const getFolderIsDisabled = (processing: IProcessing) => {
  return processingStatusesForDisabling.includes(processing.status);
};

const List: FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { teamStore, jobStore, processingStore, mapStore } = useRootStore();
  const { isOnboardingRunning } = onboardingStore;
  const ref = createRef<HTMLDivElement>();
  const [isOverflow, setIsOverflow] = useState(false);
  const { csPermissions } = teamStore;
  const { job } = jobStore;
  const { processings, filteredProcessings, processing } = processingStore;

  useLayoutEffect(() => {
    const node = ref.current;

    if (node && node.clientHeight < node.scrollHeight) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, [ref]);

  const handleSelectProcessing = async (processingId: string) => {
    if (processingId === processing?.id) {
      return;
    }

    mapStore.clearProcessing();
    navigate(absolutePath(`processing/${processingId}`));

    if (isOnboardingRunning) {
      onboardingStore.nextStep();
    }
  };

  function getAllViewDisabled(): boolean {
    return (
      !!processings.length && processings.some(processing => processing.status === ProcessingStatus.PROCESSING_SUCCESS)
    );
  }

  const getViewerIsDisabled = (processing: IProcessing) => {
    const config = processing.config as IParamsForInitiateProcessing;
    return (
      processing.status !== ProcessingStatus.PROCESSING_SUCCESS ||
      isOnboardingRunning ||
      (processing.type === Processings.VIPOINT && config?.scriptId === 16)
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="caption" fontWeight={300} sx={{ mr: 1, color: "#ADADAD" }}>
          {`${t("Processings:QuantityProcessings")}: ${processings.length}`}
        </Typography>
        <Box>
          {job && (
            <IconButton
              sx={iconStyless({ borderRadius: "5px", padding: "5px" })}
              onClick={() => processingStore.setViewerOpen(true, job)}
              disabled={!getAllViewDisabled()}>
              <VisibilityOutlinedIcon />
              <Typography variant="caption" fontWeight={300} sx={{ ml: 0.5, color: "#ADADAD" }}>
                {t("Processings:ViewAll")}
              </Typography>
            </IconButton>
          )}
        </Box>
      </Box>
      <Box
        ref={ref}
        sx={isOverflow ? { ...containerStyles, pr: 1, MozPaddingEnd: "0" } : containerStyles}
        id="processings">
        {filteredProcessings.map((processing: IProcessing) => {
          const processingName = t(`Processings:${processing.type}`).split(" ")[0];
          const imageStyles = getImageStyles(processingName);
          return (
            <Fade in key={processing.id}>
              <Paper elevation={0} sx={elemStyles}>
                <Paper
                  elevation={0}
                  sx={processing.type.includes("GCP") ? { ...imageStyles, "&:before": GCPMarker } : imageStyles}>
                  <img
                    src={`/images/types/${processing.type}.svg`}
                    alt={processing.type}
                    width="40"
                    style={{ transform: "translateY(5px)" }}
                  />
                  <Typography variant="body1" component="div">
                    {processingName}
                  </Typography>
                </Paper>
                <Box sx={innerContainerStyles}>
                  <Typography variant="body1" component="div" fontWeight={600}>
                    {processing.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <Status<ProcessingStatus>
                      status={processing.status}
                      statusesMatch={{
                        CREATE_SUCCESS: StatusName.SUCCESS,
                        PROCESSING_SUCCESS: StatusName.SUCCESS,
                        PROCESSING_PENDING: StatusName.IN_PROGRESS,
                        PROCESSING_IN_PROGRESS: StatusName.IN_PROGRESS,
                        PROCESSING_FAIL: StatusName.FAIL,
                        CREATE_IN_PROGRESS: StatusName.IN_PROGRESS,
                      }}
                      updateFunc={() => processingStore.getAllProcessings(job!.id)}
                    />
                    <Box sx={buttonsWrapperStyles}>
                      <IconButton
                        sx={iconStyles}
                        onClick={() => processingStore.setViewerOpen(true, processing)}
                        disabled={getViewerIsDisabled(processing)}>
                        <VisibilityOutlinedIcon />
                      </IconButton>
                      <IconButton
                        sx={iconStyles}
                        onClick={async () => await handleSelectProcessing(processing.id)}
                        disabled={getFolderIsDisabled(processing)}>
                        <FolderOpenOutlinedIcon fontSize="small" />
                      </IconButton>
                      {!getFolderIsDisabled(processing) &&
                        !isOnboardingRunning &&
                        csPermissions.creating && (
                          <ActionButtons iconStyles={iconStyles} processing={processing} />
                        )}
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Fade>
          );
        })}
      </Box>
    </>
  );
});

const iconStyless = (obj?: { borderRadius: string; padding: string }) => {
  return {
    ...obj!,
    ml: 1,
    bgcolor: "secondary.light",
    color: "text.primary",
    "& .MuiSvgIcon-root": {
      color: "text.primary",
      transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "&:hover": {
      bgcolor: "secondary.light",
      "& .MuiSvgIcon-root": {
        color: "primary.main",
      },
    },
    "&.Mui-disabled": {
      bgcolor: "secondary.main",
      "& .MuiSvgIcon-root": {
        opacity: 0.2,
      },
    },
  };
};

const containerStyles = {
  mt: "10px",
  ...cardMaxHeightProps("processing"),
  minHeight: 0,
  ...thumbStyles,
} as const;

const elemStyles = {
  mb: 0.5,
  bgcolor: "background.paper",
  borderRadius: "5px",
  display: "flex",
  alignItems: "stretch",
  "&:last-of-type": {
    mb: 0,
  },
} as const;

const getImageStyles = (processing: string) =>
  ({
    position: "relative",
    bgcolor: "text.primary",
    borderRadius: "5px",
    height: "85px",
    minWidth: "90px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& .MuiTypography-root": {
      mt: "15px",
      color: "secondary.light",
      fontWeight: "500",
      fontSize: (processing.length > 8 && "12px") || "16px",
      lineHeight: "1",
      textTransform: "uppercase",
    },
  } as const);

const GCPMarker = {
  p: "0 4px",
  content: '"GCP"',
  color: "secondary.main",
  fontSize: "11px",
  position: "absolute",
  top: 0,
  right: 0,
  bgcolor: "#D8D8D8",
  borderRadius: "0 5px",
} as const;

const innerContainerStyles = {
  m: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  minWidth: 0,
  "& .MuiTypography-root": {
    lineHeight: 1,
    pt: "8px",
    minWidth: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .MuiCircularProgress-root": {
    display: "inline-flex",
  },
} as const;

const buttonsWrapperStyles = {
  display: "flex",
  alignItems: "center",
};

const iconStyles = {
  ml: "4px",
  width: "32px",
  height: "32px",
  bgcolor: "background.default",
  color: "text.primary",
  "& .MuiSvgIcon-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    color: "text.primary",
    fontSize: "1rem",
  },
  "&:hover": {
    bgcolor: "background.default",
    "& .MuiSvgIcon-root": {
      color: "primary.main",
    },
  },
  "&.Mui-disabled": {
    bgcolor: "secondary.main",
    "& .MuiSvgIcon-root": {
      opacity: 0.2,
    },
  },
};

export default List;
