export const getSettingsLocale = (id: string) => `Settings:${id}`;

export const LOCALES = {
  USER_PROFILE: getSettingsLocale("UserProfile"),
  COMPANY_PROFILE: getSettingsLocale("CompanyProfile"),
  ACCOUNT: getSettingsLocale("Account"),
  NOTIFICATIONS: getSettingsLocale("Notifications"),
  INTEGRATIONS: getSettingsLocale("Integrations"),
  ADD_INTEGRATION: getSettingsLocale("AddIntegration"),
  CLIENT_ID: getSettingsLocale("ClientId"),
  CLIENT_SECRET: getSettingsLocale("ClientSecret"),
  NAME: getSettingsLocale("Name"),
  PIX4D: getSettingsLocale("Pix4D"),
  TYPE: getSettingsLocale("Pix4D"),
  INCORRECT_INFO: getSettingsLocale("IncorrectInfo"),
  NO_INTEGRATION: getSettingsLocale("NoIntegration"),
  SUCCESSFULLY_CREATED: getSettingsLocale("SuccessfullyCreated"),
};
