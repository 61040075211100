import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { DateRange } from "components/Metrics";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface MonthSelect {
  setStartAndEndDateInMilliseconds: ({ fromDate, toDate }: DateRange) => void;
}

export const MonthSelect: React.FC<MonthSelect> = ({ setStartAndEndDateInMilliseconds }) => {
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const { t } = useTranslation();

  const handleMonthChange = (event: SelectChangeEvent<`${number}-${number}`>) => {
    const [month, year] = (event.target.value as string).split("-").map(Number);
    setSelectedMonth(month);
    setSelectedYear(year);

    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    setStartAndEndDateInMilliseconds({ fromDate: startDate.getTime(), toDate: endDate.getTime() });
  };

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number;
    setSelectedYear(value);
    const startDate = new Date(value, selectedMonth, 1);
    const endDate = new Date(value, selectedMonth + 1, 0);

    setStartAndEndDateInMilliseconds({ fromDate: startDate.getTime(), toDate: endDate.getTime() });
  };

  return (
    <FormControl variant="outlined" size="small" sx={{ paddingLeft: "11px" }}>
      <Select
        sx={{ fontSize: 14, "& .MuiOutlinedInput-notchedOutline": { borderColor: "#ADADAD" } }}
        value={`${selectedMonth}-${selectedYear}`}
        onChange={handleMonthChange}
        displayEmpty
        renderValue={selected => {
          const [month, year] = (selected as string).split("-").map(Number);
          return `${months[month]} ${year}`;
        }}>
        {months.map((monthName, index) => (
          <MenuItem sx={{ fontSize: 14 }} key={monthName} value={`${index}-${selectedYear}`}>
            {`${t(`Metrics:${monthName}`)} ${selectedYear}`}
          </MenuItem>
        ))}
        <MenuItem>
          <Select
            sx={{ fontSize: 14, "& .MuiOutlinedInput-notchedOutline": { borderColor: "#ADADAD" } }}
            value={selectedYear}
            onChange={handleYearChange}
            size="small">
            {Array.from({ length: 3 }).map((_, index) => {
              const item = currentYear - index;
              return (
                <MenuItem sx={{ fontSize: 14 }} disabled={item === selectedYear} key={item} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
