import { S3 } from "aws-sdk";
import { Processings } from "stores/managerService/processingStore/types";

export const enum FileName {
  ORTHOMOSAIC = "ORTHOMOSAIC",
  DSM = "DSM",
  POINT_CLOUD = "POINT_CLOUD",
  MESH_OBJ = "MESH_OBJ",
  MESH_MTL = "MESH_MTL",
  MESH_TEXTURE_JPG = "MESH_TEXTURE_JPG",
  MESH_OFFSET_XYZ = "MESH_OFFSET_XYZ",
  NDVI = "NDVI",
  IMAGE = "IMAGE",
  DEPTHMAP = "DEPTHMAP",
  CONFIDENCE = "CONFIDENCE",
  GCP = "GCP",
  TEMPLATE = "TEMPLATE",
  VIPOINT_POINT_CLOUD = "VIPOINT_POINT_CLOUD",
  DXF = "DXF",
  DXF_OUTPUT = "DXF_OUTPUT",
  DOCUMENT = "DOCUMENT",
  MESH_OBJ_ZIP = "MESH_OBJ_ZIP",
  IFC = "IFC",
  VP_CSV = "VP_CSV",
  AGISOFT_IMAGE = "AGISOFT_IMAGE",
  FIX_POINT_CALIBRATION_PROTOCOL = "FIX_POINT_CALIBRATION_PROTOCOL",
  MEASUREMENT_IMPORT = "MEASUREMENT_IMPORT",
}

export const enum FileExt {
  tiff = "tiff",
  tif = "tif",
  las = "las",
  obj = "obj",
  mtl = "mtl",
  jpg = "jpg",
  jpeg = "jpeg",
  xyz = "xyz",
  csv = "csv",
  tmpl = "tmpl",
  dxf = "dxf",
  pdf = "pdf",
  doc = "doc",
  docx = "docx",
  xls = "xls",
  xlsx = "xlsx",
  ppt = "ppt",
  pptx = "pptx",
  zip = "zip",
  ifc = "ifc",
  kml = "kml",
  dwg = "dwg",
  geojson = "geojson",
  xml = "xml",
  png = "png",
  txt = "txt",
}

export interface IFile {
  aclId: string;
  createdAt: string;
  createdBy: string;
  fileMeta: {
    name: string;
    size: number;
    type: string;
  };
  foreignId: string;
  id: string;
  status: FileStatus;
  storageMeta: Record<string, any>;
  storageProvider: StorageProvider;
  subsystem: SubSystem;
  subtype: SubType;
  ttl: number;
  type: FileName | Reports;
  updatedAt: string;
  version: number;
  url?: string;
}

export interface IFilesFromInput {
  fileType: FileType;
  files: File[];
}

export interface InfoPanel {
  open: boolean;
  fileType: FileType | null;
}

export const enum FileStatus {
  UPLOAD_PENDING = "UPLOAD_PENDING",
  UPLOAD_SUCCESS = "UPLOAD_SUCCESS",
  REMOVE_PENDING = "REMOVE_PENDING",
  UPLOAD_FAIL = "UPLOAD_FAIL",
  REMOVE_SUCCESS = "REMOVE_SUCCESS",
  REMOVE_FAIL = "REMOVE_FAIL",
  ARCHIVE_PENDING = "ARCHIVE_PENDING",
  ARCHIVE_SUCCESS = "ARCHIVE_SUCCESS",
  ARCHIVE_FAIL = "ARCHIVE_FAIL",
}

export const enum SubSystem {
  PIX4D = "PIX4D",
  VIGRAM = "VIGRAM",
}

export const enum SubType {
  INPUT = "INPUT",
  OUTPUT = "OUTPUT",
}

export const enum StorageProvider {
  PIX4D = "S3_PIX4D",
  VIGRAM = "S3_VIGRAM",
  VIGRAM_TEMPLATE = "S3_VIGRAM_TEMPLATE",
  VIPOINT = "S3_VIPOINT",
  // PIX4D_BACKUP = "S3_PIX4D_BACKUP",
}

export interface FileType {
  name: FileName;
  ext: FileExt | FileExt[];
  subsystem: SubSystem;
  subtype: SubType | null;
}

export enum Reports {
  REPORT_QUALITY = "REPORT_QUALITY",
  REPORT_LOG = "REPORT_LOG",
  REPORT_QUALITY_GCP = "REPORT_QUALITY_GCP",
  // REPORT_CAMERA_PARAMS = "REPORT_CAMERA_PARAMS",
}

export interface StartUploadParams {
  files: File[];
  foreignId: string;
  aclId: string;
  fileType: FileType;
  projectId?: number | string;
  processingType?: Processings;
}

export interface ApiResponse {
  status: string;
  payload: IFile;
}

export interface FilesToRegister {
  foreignId: string;
  aclId: string;
  type: string;
  files: Array<{ name: string; size: number; type: string }>;
}

export interface ICredentials {
  bucket: string;
  region: string;
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  key: string;
}

export interface IStorageMeta {
  key: string;
  bucket: string;
  region: string;
  metadata: { id: string; foreignId: string; aclId: string };
}

export interface IFileUpload extends S3.Types.ManagedUpload {
  body: File;
  loaded: number;
  storageMeta: IStorageMeta;
  status: string;
  Metadata: { id: string; foreignId: string; aclId: string };
  fileType: FileName | Reports;
}

export interface GetAllFilesResponse {
  files: IFile[];
  count: number;
  limit?: number;
  lastId?: string;
  totalCount?: number;
}

export interface ReuseBody {
  aclId: string;
  foreignId: string;
  sourceFiles: Array<{
    id: string;
    foreignId: string;
  }>;
}
export interface PostResponse {
  data: {
    status: string;
    payload: {
      successful: IFile[];
      failed: [];
    };
  };
}

export interface GetFilesByQueryParams {
  lastId?: string | null;
  subtype?: SubType | null;
  totalCount?: boolean;
  type?: FileName;
  jobId?: string;
  aclId?: string;
  foreignIds?: string;
  subsystem?: SubSystem;
  withTotalCount?: boolean;
  status?: FileStatus;
  id?: string;
  limit?: number;
}

export enum StorageOperationMode {
  GetObject = "getObject",
  PutObject = "putObject",
}
