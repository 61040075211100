import { NavigateFunction } from "react-router-dom";
import { intercept, makeAutoObservable } from "mobx";

import { AuthStore } from "stores/authStore";
import { EmailStore } from "stores/emailStore";
import { MainStore } from "stores/mainStore";
import { UIStore } from "stores/uiStore";

import { AreaStore } from "./managerService/areaStore";
import { ConstructionSiteStore } from "./managerService/constructionSiteStore";
import { JobStore } from "./managerService/jobStore";
import { ProcessingStore } from "./managerService/processingStore";
import { TeamStore } from "./managerService/teamStore";
import { TasksExportStore } from "./taskStore/exportTasksStore";
import { FileStore } from "./fileStore";
import { ImportFilesStore } from "./importFilesStore";
import { MapStore } from "./mapStore";
import { MeasurementStore } from "./measurementStore";
import { MetricStore } from "./metricStore";
import { NavigateStore } from "./navigateStore";
import { SettingsStore } from "./settingStore";
import { TaskStore } from "./taskStore";
import { UsersStore } from "./userStore";
import { FixPointStore } from "./fixPointStore";
import { IntegrationStore } from "./integrationStore";

class RootStore {
  readonly authStore: AuthStore;
  readonly emailStore: EmailStore;
  readonly usersStore: UsersStore;

  readonly fileStore: FileStore;
  readonly importFileStore: ImportFilesStore;

  readonly mainStore: MainStore;

  // managerService
  readonly areaStore: AreaStore;
  readonly constructionSiteStore: ConstructionSiteStore;
  readonly jobStore: JobStore;
  readonly processingStore: ProcessingStore;
  readonly teamStore: TeamStore;

  readonly mapStore: MapStore;
  readonly measurementStore: MeasurementStore;
  readonly settingsStore: SettingsStore;
  readonly taskStore: TaskStore;
  readonly tasksExportStore: TasksExportStore;

  readonly uiStore: UIStore;

  readonly navigateStore: NavigateStore;
  readonly metricStore: MetricStore;
  readonly fixPointStore: FixPointStore;
  readonly integrationStore: IntegrationStore;

  constructor(navigate: NavigateFunction) {
    makeAutoObservable(this);
    this.authStore = new AuthStore(this);
    this.emailStore = new EmailStore(this);
    this.usersStore = new UsersStore(this);

    this.fileStore = new FileStore(this);
    this.importFileStore = new ImportFilesStore(this);

    this.mainStore = new MainStore(this);

    this.areaStore = new AreaStore(this);
    this.constructionSiteStore = new ConstructionSiteStore(this);
    this.jobStore = new JobStore(this);
    this.processingStore = new ProcessingStore(this);
    this.teamStore = new TeamStore(this);

    this.mapStore = new MapStore(this);
    this.measurementStore = new MeasurementStore(this);
    this.settingsStore = new SettingsStore(this);
    this.taskStore = new TaskStore(this);
    this.tasksExportStore = new TasksExportStore(this);

    this.uiStore = new UIStore(this);

    this.navigateStore = new NavigateStore(this, navigate);
    this.metricStore = new MetricStore(this);
    this.fixPointStore = new FixPointStore(this);
    this.integrationStore = new IntegrationStore(this);
  }
}

export default RootStore;
