import { useTranslation } from "react-i18next";
import { format as dformat, isSameMonth, isSameYear, startOfMonth } from "date-fns";
import { useFormatDate } from "./useFormatDate";

export interface DateRange {
  fromDate?: number;
  toDate?: number;
}

export const useFormattedDateRange=()=> {
  const { t } = useTranslation();
  const format = useFormatDate(dformat);

  return function formatDateRange({ fromDate, toDate }: DateRange) {
    const now = new Date();
    const startDate = fromDate ? new Date(fromDate) : startOfMonth(now);
    const endDate = toDate ? new Date(toDate) : now;

    if (isSameMonth(startDate, endDate)) {
      const monthAndYear = isSameYear(startDate, now) ? format(endDate, "LLLL") : format(endDate, "LLLL yyyy");
      return t("Metrics:sameMonth", { startDate: format(startDate, "d"), endDate: format(endDate, "d"), monthAndYear });
    }

    const startFormat = isSameYear(startDate, now) ? "d LLLL" : "d LLLL yyyy";
    const endFormat = isSameYear(endDate, now) ? "d LLLL" : "d LLLL yyyy";
    return t("Metrics:differentMonths", {
      startDate: format(startDate, startFormat),
      endDate: format(endDate, endFormat),
    });
  };
}