import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, SxProps, Theme } from "@mui/material";
import { observer } from "mobx-react-lite";

import { FILE_TYPES } from "stores/fileStore/constants";
import { fileService } from "stores/fileStore/service";
import { FileName, FileStatus, IFile, GetFilesByQueryParams } from "stores/fileStore/types";

import { thumbStyles } from "constants/styles";
import { useFetchWithLoading } from "hooks";
import { useRootStore } from "hooks/useRootStore";

import FileInput, { FileInputProps } from "../FileInput";
import { Loader } from "../Loader";
import { NoDataMessage } from "../NoDataMessage";

import AttachmentsItem from "./AttachmentsItem";

interface AttachmentsProps {
  id: string;
  acl: string;
  buttonTitle?: FileInputProps["buttonTitle"];
  disabled?: boolean;
}

const Attachments: React.FC<AttachmentsProps> = ({ id, acl, buttonTitle, disabled }) => {
  const { fileStore, teamStore, constructionSiteStore } = useRootStore();
  const { t } = useTranslation();

  const { isUploading } = fileStore;
  const { constructionSite } = constructionSiteStore;
  const { getCurrentUserRoleInCS, csPermissions } = teamStore;
  const [attachments, setAttachments] = useState<IFile[] | undefined>();
  const [lastId, setLastId] = useState<string | null>(null);

  const getAttachments = useCallback(async () => {
    const queryParams: GetFilesByQueryParams = {
      type: FileName.DOCUMENT,
      lastId,
      status: FileStatus.UPLOAD_SUCCESS,
    };
    const response = await fileService.getAllFiles(queryParams, id);
    setAttachments(response.files.filter(f => !!f.storageMeta?.key));
    if (response.lastId) {
      setLastId(response.lastId);
    }
  }, [id, lastId]);

  const [fetchWithLoading, isLoading] = useFetchWithLoading(getAttachments);

  useEffect(() => {
    void getCurrentUserRoleInCS(constructionSite.id);
    if (!isUploading) {
      fetchWithLoading();
    }
  }, [fetchWithLoading, isUploading]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const filesToUpload = Array.from(e.target.files!);
    await fileStore.startUpload({ files: filesToUpload, foreignId: id, aclId: acl, fileType: FILE_TYPES.DOCUMENT });
    await getAttachments();
  };

  return (
    <Box>
      <Box sx={{ textAlign: "end" }}>
        <FileInput
          key={"file-input"}
          fileTypeName={FileName.DOCUMENT}
          onChange={handleChange}
          buttonTitle={buttonTitle}
          disabled={!csPermissions.creating || !!disabled}
        />
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={listContainerStyles}>
          {attachments?.length ? (
            attachments.map(a => (
              <AttachmentsItem
                key={a.id}
                file={a}
                foreignId={id}
                acl={acl}
                getAttachments={getAttachments}
                disabledToDelete={!!disabled}
              />
            ))
          ) : (
            <NoDataMessage message={t("Files:NoFilesFound")} />
          )}
        </Box>
      )}
    </Box>
  );
};

const listContainerStyles: SxProps<Theme> = {
  marginTop: "15px",
  maxHeight: "23vh",
  ...thumbStyles,
};

export default observer(Attachments);
