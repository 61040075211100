import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import { Box, Fade, IconButton, Paper, SxProps, Theme, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSwiper } from "swiper/react";

import { IConstructionSite } from "stores/managerService/constructionSiteStore/types";

import OverflowTooltip from "components/shared/OverflowTooltip";
import { useRootStore } from "hooks/useRootStore";

interface SliderItemProps {
  el: IConstructionSite;
  index: number;
}

const SliderItem: FC<SliderItemProps> = ({ el, index }) => {
  const navigate = useNavigate();

  const { mapStore, constructionSiteStore } = useRootStore();

  const swiper = useSwiper();
  const { sliderIndex } = mapStore;
  const { constructionSite, isSliderCardExpanded, setIsConstructionSitesBottomPanelOpen } = constructionSiteStore;

  const handleClick = async (id: string, index: number) => {
    if (id !== constructionSite.id) {
      swiper.slideTo(index);
      mapStore.clearAreaAndNested();
      navigate(`construction_site/${id}`);
      setIsConstructionSitesBottomPanelOpen(false);
    }
  };

  useEffect(() => {
    sliderIndex && swiper.slideTo(sliderIndex);
  }, [sliderIndex, swiper]);

  const [sliderElement, setSliderElement] = useState<HTMLElement | null>(null);

  return (
    <Paper elevation={0} sx={elementStyles} onClick={async () => await handleClick(el.id, index)}>
      {el.id === constructionSite.id && !isSliderCardExpanded && (
        <Fade in>
          <Box sx={iconStyles}>
            <IconButton
              sx={{ color: "text.primary" }}
              onClick={() => constructionSiteStore.setIsSliderCardExpanded(true)}>
              <SubjectRoundedIcon fontSize="small" />
            </IconButton>
          </Box>
        </Fade>
      )}
      <OverflowTooltip placement={"top"} element={sliderElement} title={el.title}>
        <Typography
          noWrap
          ref={instance => setSliderElement(instance)}
          variant="body1"
          component="div"
          fontWeight={500}
          color={el.id === constructionSite.id ? "primary" : "text"}>
          {el.title}
        </Typography>
      </OverflowTooltip>
    </Paper>
  );
};

export const elementStyles: SxProps<Theme> = {
  px: 1,
  position: "relative",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
  "& .MuiTypography-root": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    cursor: "pointer",
    "& .MuiTypography-root": {
      color: "primary.main",
    },
  },
};

const iconStyles: SxProps<Theme> = {
  position: "absolute",
  bottom: "40px",
  bgcolor: "background.default",
  borderRadius: "50%",
  "& .MuiIconButton-root": {
    "& .MuiSvgIcon-root": {
      color: "text.primary",
      transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "&:hover": {
      background: "transparent",
      "& .MuiSvgIcon-root": {
        color: "primary.main",
      },
    },
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.07)",
  },
};

export default observer(SliderItem);
