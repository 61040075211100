export enum MetricKey {
  // ownerLicence = "ownerLicence",
  // webUsers = "webUsers",
  // mobileUsers = "mobileUsers",
  droneAndCrane = "droneAndCrane",
  vidocAndPix4d = "vidocAndPix4d",
  vipoint = "vipoint",
  viplan = "viplan",
  liveStorage = "liveStorage",
  archiveStorage = "archiveStorage",
}

export interface BillingReportItem {
  stripeSubscriptionItemId: string;
  constructionSiteId: string;
  createdAt: number;
  quantity: number;
  stripeUsageRecordId: string;
  triggeredUserId: string;
  subscriptionItemKey: string;
  updatedAt: number;
}

export interface GetByMetricKeyResponse {
  status: "ok" | "error";
  payload: BillingReportItem[];
}

export interface GetByCSIdResponse {
  status: "ok" | "error";
  payload: {
    [MetricKey: string]: BillingReportItem[];
  };
}

export interface GetBillingInfoQueryParams {
  constructionSiteId: string;
  userId?: string | null;
  fromDate?: number | null;
  toDate?: number | null;
  metricKey?: MetricKey | null;
}

export type PriceRange = {
  min?: number;
  max?: number;
  price: number;
};

export type AggregatedResponse = {
  [MetricKey: string]: number;
};