import React, { FC, MouseEvent, useCallback, useEffect, useState } from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, IconButton, Typography } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { GraphicId } from "stores/mapStore/types";
import { MeasurementType } from "stores/measurementStore/types";
import { useRootStore } from "hooks/useRootStore";

import CloseIconButton from "components/shared/CloseIconButton";

import MeasurementsItemsList from "./MeasurementList";
import MeasurementsSectionsList from "./MeasurementsSectionsList";
import { Id } from "./types";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { LOCALES } from "./locales";
import { useFetchWithLoading } from "hooks";
import { inputFileStyles, thumbStyles } from "constants/styles";
import { FileName } from "stores/fileStore/types";
import { getInputAccept } from "helpers/functions";
import { FILE_TYPES } from "stores/fileStore/constants";
import { Loader } from "../Loader";
import { observer } from "mobx-react-lite";
import { iconStyles } from "../DisplayOptions/styles";
import { LoadingIndicatorOverlay } from "../LoadingIndicatorOverlay";

const Measurements: FC<Id> = observer(({ id }) => {
  const {
    jobStore: { job },
    mapStore,
    measurementStore: {
      exportMeasurements,
      isMeasurementsImportInProgress,
      listOfImportedDXFMeasurements,
      getListOfImportedDXFMeasurements,
      removeImportedDXFMeasurementFromList,
      setListOfImportedDXFMeasuremets,
    },
    fileStore: { startUpload },
  } = useRootStore();
  const [invokeRemoveImportedDXF, isRemovingImportedDXF] = useFetchWithLoading(
    removeImportedDXFMeasurementFromList
  );

  const [exportMode, setExportMode] = useState(false);
  const { t } = useTranslation();
  const [measurementType, setMeasurementType] = useState<MeasurementType | null>(null);
  const [measurementsForExport, setMeasurementsForExport] = useState<string[]>([]);
  const [invokeExportMeasurements, isLoading] = useFetchWithLoading(exportMeasurements);
  const [invokeUploadFiles, isUploadingFiles] = useFetchWithLoading(startUpload);

  const handleSelectMeasurementForExport = (id: string) => {
    setMeasurementsForExport(prev => {
      if (prev.includes(id)) {
        return prev.filter(i => i !== id);
      } else return [...prev, id];
    });
  };

  const zoomToCurrentJob = useCallback(async () => {
    if (!job?.id) {
      return;
    }

    const currentJobGraphic = mapStore.utils.getGraphicByItemId(job.id);
    currentJobGraphic && (await mapStore.utils.zoomToGeometry(currentJobGraphic.geometry));
  }, [job?.id, mapStore]);

  const handleBack = useCallback(async () => {
    setMeasurementType(null);
    mapStore.utils.removeGraphicsByGraphicType(GraphicId.measurement);

    if (measurementType) {
      await zoomToCurrentJob();
    }
  }, [measurementType, zoomToCurrentJob]);

  async function handleDownloadMeasurements() {
    await invokeExportMeasurements(job!.id, measurementsForExport);
    setMeasurementsForExport([]);
    setExportMode(false);
  }

  useEffect(() => {
    const close = () => {
      handleBack();
      setListOfImportedDXFMeasuremets([]);
    };

    return close;
    // it should be called only after unmounting the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    void getListOfImportedDXFMeasurements(job!.id);
  }, [job]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const filesToUpload = Array.from(e.target.files!);
    await invokeUploadFiles({
      files: filesToUpload,
      foreignId: id,
      aclId: job!.acl,
      fileType: FILE_TYPES.MEASUREMENT_IMPORT,
    });
  };

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  return (
    <Box>
      <Button
        component="label"
        size="small"
        color="secondary"
        variant="contained"
        fullWidth
        sx={{ flexShrink: 0, mb: 0.5 }}>
        {isUploadingFiles || isMeasurementsImportInProgress ? (
          <Loader withoutFreeSpace size="sm" />
        ) : (
          <AddRoundedIcon />
        )}
        <Typography sx={{ ml: 0.5 }} variant="caption">
          {t("Measurements:Import")}
        </Typography>
        <input
          type="file"
          multiple
          hidden
          accept={getInputAccept(FILE_TYPES[FileName.MEASUREMENT_IMPORT].ext)}
          onClick={handleClick}
          onChange={handleChange}
        />
      </Button>
      {measurementType ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}>
            <Box>
              <Button
                sx={{
                  backgroundColor: exportMode ? "secondary.light" : "secondary.main",
                }}
                onClick={() => setExportMode(prev => !prev)}>
                {t(exportMode ? LOCALES.CANCEL : LOCALES.EXPORT)}
              </Button>
              {exportMode && (
                <LoadingButton
                  startIcon={<FileDownloadIcon />}
                  onClick={handleDownloadMeasurements}
                  disabled={!measurementsForExport.length}
                  loading={isLoading}>
                  {t(isLoading ? LOCALES.PREPARING : LOCALES.DOWNLOAD)}
                </LoadingButton>
              )}
            </Box>
            <CloseIconButton onClick={handleBack} icon={<ArrowBackRoundedIcon />} />
          </Box>
          <MeasurementsItemsList
            handleSelectMeasurementForExport={handleSelectMeasurementForExport}
            exportMode={exportMode}
            measurementsForExport={measurementsForExport}
            selectedMeasurementType={measurementType}
            id={id}
          />
        </>
      ) : (
        <Box>
          <MeasurementsSectionsList setMeasurementType={setMeasurementType} />
          {!!listOfImportedDXFMeasurements.length && (
            <Box>
              <Typography textAlign="center" mb={1}>
                {t("Measurements:Imported")}
              </Typography>
              <Box sx={{ ...thumbStyles, minHeight: 60, maxHeight: 200 }}>
                {listOfImportedDXFMeasurements.map(file => (
                  <Box
                    sx={{
                      ...inputFileStyles,
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 0.5,
                      alignItems: "center",
                    }}>
                    <Typography variant="caption" fontWeight={300} sx={{ mr: 1, color: "#ADADAD" }}>
                      {file.fileMeta.name}
                    </Typography>
                    <Box>
                      <IconButton
                        sx={iconStyles}
                        onClick={async () =>
                          await invokeRemoveImportedDXF(file.foreignId, file.id)
                        }>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
});

export default Measurements;
