import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Divider, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { TeamRole } from "stores/managerService/teamStore/types";
import { onboardingStore } from "stores/onboardingStore";

import ClearingTextField from "components/shared/ClearingTextField";
import { LOCALES } from "constants/locales";
import { useRootStore } from "hooks/useRootStore";
import { absolutePath, PATHS } from "router/paths";

const CreatePopupForm: FC<{ constructionSiteId: string }> = ({ constructionSiteId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    mapStore: { kmlFileName },
    areaStore,
    uiStore: { setSnackbarOpen },
    teamStore: { currentUserRoleInCS },
  } = useRootStore();
  const { isOnboardingRunning } = onboardingStore;

  const [title, setTitle] = useState(kmlFileName ?? "");
  const [description, setDescription] = useState("");
  const [isProtected, setIsProtected] = useState(false);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const area = await areaStore.createArea(constructionSiteId, title, description, isProtected);

    if (area === null) {
      setSnackbarOpen(LOCALES.ERRORS.SOME_ERROR);
      return;
    }

    navigate(absolutePath(PATHS.HOME.AREA_CARD(area.id)));
    if (isOnboardingRunning) {
      onboardingStore.nextStep();
    }
  };

  const isAdmin = currentUserRoleInCS === TeamRole.OWNER || currentUserRoleInCS === TeamRole.ADMIN;

  return (
    <form onSubmit={handleSubmit}>
      <Typography id="transition-modal-title" variant="h5" component="h2" textAlign="center" sx={{ mb: 2 }}>
        {t("Area:CreateArea")}
      </Typography>
      <Divider sx={{ mx: -1.5 }} />
      <ClearingTextField
        id="title"
        label={t("General:Title")}
        fullWidth
        sx={{ mt: 2.5 }}
        required
        value={title}
        onChange={e => setTitle(e.target.value)}
        inputProps={{ maxLength: 255 }}
        onClear={() => setTitle("")}
      />
      <TextField
        id="description"
        label={t("General:Description")}
        multiline
        rows={3}
        fullWidth
        sx={{ my: 2 }}
        value={description}
        onChange={e => setDescription(e.target.value)}
        inputProps={{ maxLength: 255 }}
      />
      {isAdmin && (
        <Box>
          <FormControlLabel
            label={t("Area:MakePrivate")}
            control={
              <Checkbox
                color="error"
                checked={isProtected}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsProtected(e.target.checked)}
              />
            }
          />
          <Typography variant="caption" component="div" sx={{ p: 1, color: "text.secondary" }}>
            {t("Area:MakePrivateDescr")}
          </Typography>
        </Box>
      )}
      <Button color="secondary" variant="contained" fullWidth type="submit">
        {t("General:Submit")}
      </Button>
    </form>
  );
};

export default CreatePopupForm;
