import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Stack, SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { GetTeamBy } from "stores/managerService/teamStore/types";

import AddTaskPopup from "components/shared/AddTaskPopup";
import { thumbStyles } from "constants/styles";
import { useRootStore } from "hooks/useRootStore";

import Card from "../Card";
import EmptyCard from "../Card/EmptyCard";

import ExportTasks from "./ExportTasks";
import TasksSearchAndFilters from "./TasksSearchAndFilters";

enum TaskStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  COMPLETED = "COMPLETED",
}

const ConstructionSiteTasks: FC = () => {
  const { t } = useTranslation();
  const {
    constructionSiteStore: { constructionSites, getAll },
    taskStore: { resetFilters, search, setSearch, searchedTasks, filters, setFilter, getAllTasks },
    tasksExportStore: { disableExportMode },
    teamStore: { getCSTeamMembers, setConstructionSiteTeamMembers },
    uiStore,
  } = useRootStore();
  const { constructionSiteId } = useParams<{ constructionSiteId: string }>();
  const [loaded, setLoaded] = useState(false);
  const [acl, setAcl] = useState("");

  useEffect(() => {
    if (constructionSites) {
      const constructionSite = constructionSites.find(constructionSite => constructionSite.id === constructionSiteId);
      constructionSite && setAcl(constructionSite.acl);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getAll();
    }
  }, [constructionSites, constructionSiteId, getAll]);

  const fetchTasks = useCallback(
    async (constructionSiteId: string) => {
      await getAllTasks({ constructionSiteId }, true);
      await getCSTeamMembers(GetTeamBy.RESOURCE, constructionSiteId);
      setLoaded(true);
    },
    [getAllTasks, getCSTeamMembers]
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (constructionSiteId) fetchTasks(constructionSiteId);
    return () => {
      setLoaded(false);
      setConstructionSiteTeamMembers(null);
      disableExportMode();
    };
  }, [constructionSiteId, constructionSites, disableExportMode, fetchTasks, setConstructionSiteTeamMembers]);

  return (
    <Box sx={wrapperStyles}>
      <TasksSearchAndFilters
        search={search}
        setSearch={setSearch}
        filters={filters}
        setFilter={setFilter}
        resetFilters={resetFilters}
      />
      <Box sx={containerStyles}>
        <Grid container spacing={2} sx={{ "& .MuiGrid-item > .MuiButton-root": { mb: 1 } }}>
          <Grid item xs={3}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography variant="h5" component="div" sx={{ lineHeight: "28px" }}>
                {t("Tasks:PENDING")}
              </Typography>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                sx={btnStyles}
                onClick={() =>
                  constructionSiteId &&
                  uiStore.openModal(
                    <AddTaskPopup foreignId={constructionSiteId} acl={acl} status={TaskStatus.PENDING} />
                  )
                }>
                <AddRoundedIcon fontSize="small" />
              </Button>
            </Box>
            <Stack spacing={1}>
              {loaded && searchedTasks && (
                <>
                  {searchedTasks.find(task => task.status === TaskStatus.PENDING) ? (
                    searchedTasks.map(task => task.status === TaskStatus.PENDING && <Card key={task.id} task={task} />)
                  ) : (
                    <EmptyCard />
                  )}
                </>
              )}
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography variant="h5" component="div" sx={{ lineHeight: "28px" }}>
                {t("Tasks:IN_PROGRESS")}
              </Typography>
            </Box>
            <Stack spacing={1}>
              {loaded && searchedTasks && (
                <>
                  {searchedTasks.find(task => task.status === TaskStatus.IN_PROGRESS) ? (
                    searchedTasks.map(
                      task => task.status === TaskStatus.IN_PROGRESS && <Card key={task.id} task={task} />
                    )
                  ) : (
                    <EmptyCard />
                  )}
                </>
              )}
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h5" component="div" sx={{ mb: 2, lineHeight: "28px" }}>
              {t("Tasks:IN_REVIEW")}
            </Typography>
            <Stack spacing={1}>
              {loaded && searchedTasks && (
                <>
                  {searchedTasks.find(task => task.status === TaskStatus.IN_REVIEW) ? (
                    searchedTasks.map(
                      task => task.status === TaskStatus.IN_REVIEW && <Card key={task.id} task={task} />
                    )
                  ) : (
                    <EmptyCard />
                  )}
                </>
              )}
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h5" component="div" sx={{ mb: 2, lineHeight: "28px" }}>
              {t("Tasks:COMPLETED")}
            </Typography>
            <Stack spacing={1}>
              {loaded && searchedTasks && (
                <>
                  {searchedTasks.find(task => task.status === TaskStatus.COMPLETED) ? (
                    searchedTasks.map(
                      task => task.status === TaskStatus.COMPLETED && <Card key={task.id} task={task} />
                    )
                  ) : (
                    <EmptyCard />
                  )}
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
        {!loaded && (
          <Box sx={loaderWrapperStyles}>
            <CircularProgress size={30} sx={{ color: "text.primary" }} />
          </Box>
        )}
      </Box>
      {constructionSiteId !== undefined && <ExportTasks constructionSiteId={constructionSiteId} />}
    </Box>
  );
};

const wrapperStyles: SxProps<Theme> = { position: "relative", height: "100vh" };

const containerStyles = {
  pt: 2,
  pr: 2,
  pl: 4,
  height: "100%",
  ...thumbStyles,
};

const btnStyles = {
  ml: 1.5,
  height: "28px",
  width: "28px",
  minWidth: "unset",
  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.2)" },
};

const loaderWrapperStyles = {
  height: "calc(100vh)",
  transform: "translateY(-68px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default observer(ConstructionSiteTasks);
