import React, { FC, useEffect, useMemo } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { observer } from "mobx-react-lite";

import Modals from "components/shared/Modals";
import UploadsProgress from "components/ConstructionSite/Files/UploadsProgress";
import Viewer from "components/ConstructionSite/Processings/Viewer";
import Sidebar from "components/Sidebar";
import AppLoader from "containers/AppLoader";
import Notification from "containers/Notification";
import PrivateComponent from "containers/PrivateComponent";
import { useAxiosInterceptorConfig } from "hooks/useAxiosInterceptorConfig";
import { useRootStore } from "hooks/useRootStore";
import { getRoutes } from "router";
import { absolutePath, PATHS } from "router/paths";
import theme from "themes/dark";

const App: FC = () => {
  const { mainStore, authStore } = useRootStore();
  useAxiosInterceptorConfig();

  const { email } = authStore;
  const routes = useMemo(() => getRoutes(!!email), [email]);
  const element = useRoutes(routes);
  const { isAppLoaded, logoAnimationDuration } = mainStore;
  const navigate = useNavigate();

  async function verifySessionAndNavigate() {
    await authStore.verifySession().then(() => {
      if (authStore.newUser) {
        navigate(absolutePath(PATHS.SETTINGS.INDEX));
      }
    });
  }

  useEffect(() => {
    verifySessionAndNavigate().catch(e => console.error(e));
    setTimeout(() => {
      mainStore.setTimerToZero();
    }, logoAnimationDuration);

    mainStore.showBrowserWarning();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isAppLoaded ? (
        <>
          <Box sx={mainContainer}>
            <PrivateComponent component={Sidebar} />
            <Box sx={innerContainer}>{element}</Box>
          </Box>
          <UploadsProgress />
          <Viewer />
          <Modals />
          <Notification />
        </>
      ) : (
        <AppLoader />
      )}
    </ThemeProvider>
  );
};

const mainContainer = {
  height: "100%",
  width: "100%",
  overflow: "hidden",
  display: "flex",
  bgcolor: "#202020",
};

const innerContainer = {
  position: "relative",
  height: "100vh",
  width: "100%",
  minWidth: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

export default observer(App);
