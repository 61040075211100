import { LoadingIndicatorOverlay } from "components/shared/LoadingIndicatorOverlay";
import { useFetchWithLoading, useRootStore } from "hooks";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { NoIntegration } from "./NoIntegration";
import { Box, Button, Paper, Typography, Grid, IconButton, SxProps } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { IntegrationDialog } from "./Create";
import { CredentialType, Credentials } from "stores/integrationStore/types";
import { thumbStyles } from "constants/styles";
import { Theme } from "@emotion/react";
import { LOCALES, getSettingsLocale } from "../locales";
import { useTranslation } from "react-i18next";

function maskString(input: string): string {
  if (input.length <= 3) {
    return input;
  }

  const maskedSection = "*".repeat(input.length - 3);

  const lastThree = input.slice(-3);

  return maskedSection + lastThree;
}

interface CredentialCardProps {
  name: string;
  clientId: string;
  clientSecret: string;
  type: CredentialType;
}

const CredentialCard: React.FC<CredentialCardProps> = ({ name, clientId, clientSecret, type }) => {
  const { t } = useTranslation();

  return (
    <Paper sx={{ padding: 2, marginBottom: 2, backgroundColor: "#333" }}>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4}>
          <Typography
            variant="caption"
            sx={{
              color: "text.primary",
              backgroundColor: "secondary.dark",
              p: 1,
              borderRadius: 2,
              fontSize: 12,
            }}>
            {t(getSettingsLocale(type))}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="caption" sx={{ color: "text.secondary", display: "block", mb: 1 }}>
            {t(LOCALES.CLIENT_ID)} {maskString(clientId)}
          </Typography>
          <Typography variant="caption" sx={{ color: "text.secondary", display: "block" }}>
            {t(LOCALES.CLIENT_SECRET)} {clientSecret.slice(-44)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const CredentialsList = ({ credentials }: { credentials: Credentials[] }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        pr: 0.5,
        maxHeight: "80vh",
        ...thumbStyles,
      }}>
      {credentials.map(({ clientId, name, clientSecret, type }) => (
        <CredentialCard key={clientId} name={name} clientId={clientId} clientSecret={clientSecret} type={type} />
      ))}
    </Box>
  );
};

export const Integrations = observer(() => {
  const {
    integrationStore: { getCredentialsList, credentials },
  } = useRootStore();
  const [fetchCredentialsList, isLoading] = useFetchWithLoading(getCredentialsList);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchCredentialsList();
  }, []);

  if (isLoading) return <LoadingIndicatorOverlay />;

  return (
    <Box sx={{ width: "70%" }}>
      <IconButton onClick={() => setOpen(true)} sx={buttonStyles}>
        <AddIcon />
      </IconButton>
      {credentials.length ? <CredentialsList credentials={credentials} /> : <NoIntegration />}
      <IntegrationDialog open={open} onClose={() => setOpen(false)} />
    </Box>
  );
});

const buttonStyles: SxProps<Theme> = {
  color: "primary.contrastText",
  "&:hover": {
    color: "primary.main",
  },
  backgroundColor: "secondary.light",
  borderRadius: 3,
  mb: 1,
};
