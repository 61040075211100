import React, { FC } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const loaderSizes = {
  sm: 22,
  md: 30,
  lg: 35,
} as const;

interface LoaderProps {
  size?: keyof typeof loaderSizes;
  withoutFreeSpace?: true;
}

export const Loader: FC<LoaderProps> = ({ size, withoutFreeSpace }) => {
  return (
    <Box sx={getWrapperLoaderStyles(!!withoutFreeSpace)}>
      <CircularProgress size={loaderSizes[size!]} sx={{ color: "text.primary" }} />
    </Box>
  );
};

const getWrapperLoaderStyles: (withoutFreeSpace: boolean) => SxProps<Theme> = withoutFreeSpace => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: withoutFreeSpace ? "auto" : "100%",
  minHeight: withoutFreeSpace ? 0 : 200,
  height: withoutFreeSpace ? "auto" : "100%",
});