import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const NoIntegration = () => {
  const { t } = useTranslation();

  return (
    <Box sx={containerStyles}>
      <Typography color={"text.secondary"}>{t("Settings:NoIntegration")}</Typography>
    </Box>
  );
};

const containerStyles = {
  height: 200,
  borderWidth: 1,
  border: 1,
  borderColor: "secondary.light",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 3,
};
