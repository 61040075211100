import type {} from "@mui/lab/themeAugmentation";
import { createTheme } from "@mui/material";

import { Loader } from "components/shared/Loader";

const theme = createTheme({
  palette: {
    background: {
      default: "#000",
      paper: "#333",
    },
    primary: {
      main: "#ffc900",
      contrastText: "#efefef",
    },
    secondary: { light: "#444", main: "#292929", contrastText: "#efefef", dark: "#222" },
    success: { main: "#30a05b" },
    error: { main: "#ff4444" },
    text: {
      primary: "#efefef",
      secondary: "#959595",
      disabled: "#747474",
    },
  },
  spacing: 10,
  typography: {
    h4: {
      fontFamily: "'futura-pt-bold', Sans-serif",
      lineHeight: 1,
    },
    h5: {
      fontFamily: "'futura-pt-bold', Sans-serif",
      fontSize: "22px",
      lineHeight: 1,
    },
    h6: {
      fontFamily: "'futura-pt-bold', Sans-serif",
      fontSize: "16px",
      lineHeight: 1,
      textTransform: "uppercase",
    },
    button: {
      fontFamily: "'futura-pt', Sans-serif",
    },
    subtitle1: {
      fontFamily: "'futura-pt', Sans-serif",
      fontSize: "18px",
      lineHeight: 1.5,
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: "'futura-pt-bold', Sans-serif",
      fontSize: "18px",
      lineHeight: 1.25,
    },
    caption: {
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
        },
        elevation2: {
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
        },
        rounded: { borderRadius: 10 },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": { margin: "8px 0" },
          ":first-of-type": { borderRadius: "10px" },
          ":last-of-type": { borderRadius: "10px" },
          ":before": { content: "none" },
          border: "1px solid #EAEAEA",
          borderRadius: "10px",
          boxShadow: "none",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "unset",
          "&.Mui-expanded": {
            minHeight: "unset",
          },
        },
        content: {
          margin: "8px 0",
          justifyContent: "space-between",
          "&.Mui-expanded": { margin: "8px 0" },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "#F8F8F8",
          borderRadius: "0 0 10px 10px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: { width: 35, height: 35 },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "#747474",
          },
          "&:hover": {
            backgroundColor: "#3f3f3f",
          },
        },
        sizeSmall: {
          textTransform: "none",
          minHeight: "28px",
          padding: "4px 0",
        },
      },
      defaultProps: { disableElevation: true },
    },
    MuiLoadingButton: {
      defaultProps: { loadingIndicator: <Loader size={"sm"} /> },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.MuiToggleButton-root": {
            color: "#fff",
            backgroundColor: "#3f3f3f",
            "&:hover": {
              filter: "brightness(90%)",
            },
          },
          "&.Mui-selected": {
            color: "#ffc900",
            "&:hover": {
              backgroundColor: "#3f3f3f",
            },
          },
        },
      },
    },
    MuiDivider: {
      defaultProps: { color: "#333" },
    },
    MuiInput: {
      styleOverrides: {
        input: { borderRadius: "4px" },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: { color: "#959595" },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: { color: "#efefef" },
      },
    },
    MuiTooltip: {
      defaultProps: { arrow: true },
      styleOverrides: {
        tooltip: { backgroundColor: "#333", fontSize: 16 },
        arrow: { color: "#333" },
      },
    },
  },
});

export default theme;
