import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography, Radio } from "@mui/material";
import { useEffect, useState } from "react";
import { useRootStore } from "hooks/useRootStore";
import { observer } from "mobx-react-lite";
import { Member } from "stores/managerService/teamStore/types";

interface UserListProps {
  users: Member[];
}

export const UserSelector: React.FC<UserListProps> = observer(({ users }) => {
  const {
    metricStore: { setQueryParams },
  } = useRootStore();

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleChange = (id: string) => {
    setSelectedId(id);
  };
  useEffect(() => {
    if (selectedId) {
      setQueryParams({ userId: selectedId });
    }
  }, [selectedId]);

  return (
    <List>
      {users.map(user => (
        <ListItem key={user.id} dense>
          <Radio edge="start" tabIndex={-1} checked={selectedId === user.userId} onChange={() => handleChange(user.userId)} />
          <ListItemAvatar>
            <Avatar alt={user.id} src={user.picture} />
          </ListItemAvatar>
          <ListItemText
            primary={`${user.given_name} ${user.family_name}`}
            secondary={
              <Typography variant="body2" color="textSecondary">
                {user.email}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );
});
