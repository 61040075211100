import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from "react-virtualized";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { FileName } from "stores/fileStore/types";
import { Processings, ProcessingStatus } from "stores/managerService/processingStore/types";

import { CARD_WIDTH } from "components/shared/Card/constants";
import { ViPointProcessing } from "components/ConstructionSite/Processings/ViPoint";
import { inputFileStyles } from "constants/styles";
import { formatBytes } from "helpers/functions";
import { useFetchWithLoading } from "hooks";
import { useRootStore } from "hooks/useRootStore";

const HEIGHT = 240;
const WIDTH = CARD_WIDTH - 20; // -20px for correct displaying

enum ButtonText {
  SETUP = "SetUp",
  START = "Start",
}

const Inputs: FC = () => {
  const cache = useRef(new CellMeasurerCache({ fixedWidth: true, defaultHeight: 62 }));
  const { t } = useTranslation();
  const { processingStore, fileStore, measurementStore, uiStore, mapStore } = useRootStore();

  const { processing } = processingStore;
  const { inputs, totalCount, isUploading } = fileStore;
  const { pointsIsFetching } = measurementStore;
  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  useEffect(() => {
    if (inputs.length > 0) {
      const scrollHeight = document.getElementsByClassName("ReactVirtualized__Grid__innerScrollContainer")[0]
        .clientHeight;

      if (scrollHeight > HEIGHT) {
        setIsOverflow(true);
      } else {
        setIsOverflow(false);
      }
    }
  }, [inputs.length]);

  const LOADING = 1;
  const LOADED = 2;

  const itemStatusMap: { [key: number]: number } = {};

  const loadMoreRows = async ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
    for (let index = startIndex; index <= stopIndex; index++) {
      itemStatusMap[index] = LOADING;
    }

    await new Promise(r => setTimeout(r, 1000));

    for (let index = startIndex; index <= stopIndex; index++) {
      itemStatusMap[index] = LOADED;
    }
  };

  const openVipointProcessingModal = () => {
    processing &&
      uiStore.openModal(<ViPointProcessing id={processing.id} />, {
        styles: {
          width: "800px",
          height: "800px",
        },
      });
  };

  const [fetchRunProcessingWithLoading, ProcessingIsRunning] = useFetchWithLoading(
    async (id: string) => await processingStore.launchProcessing(id)
  );
  const startProcessing = async (id: string) => {
    return await fetchRunProcessingWithLoading(id);
  };
  function getIsDisabled() {
    return ProcessingIsRunning || processing?.status !== ProcessingStatus.CREATE_SUCCESS || isUploading;
  }

  function getButtonText(isVipoint: boolean) {
    switch (processing?.status) {
      case ProcessingStatus.CREATE_SUCCESS:
        if (isVipoint) {
          return ButtonText.SETUP;
        } else return ButtonText.START;
      default:
        return processing?.status;
    }
  }
  return (
    <>
      {processing?.id && inputs && (
        <Box sx={containerStyles}>
          {inputs.some(input => input.type === FileName.IMAGE) && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mt: "-5px",
                mb: "5px",
                alignItems: "center",
              }}>
              <Typography variant="caption" fontWeight={300} sx={{ mr: 1, color: "#ADADAD" }}>
                {`${t("Processings:Quantityfiles")}: ${inputs.length}`}
              </Typography>
              <Box>
                <IconButton
                  sx={iconStyles({ borderRadius: "5px", padding: "5px" })}
                  onClick={async () => await mapStore.tooglePointVisible(processing.id)}
                  disabled={pointsIsFetching === processing?.id}>
                  {pointsIsFetching === processing?.id && (
                    <CircularProgress sx={{ position: "absolute" }} size="1rem" />
                  )}
                  <MyLocationIcon />
                  <Typography variant="caption" fontWeight={300} sx={{ ml: 0.5, color: "#ADADAD" }}>
                    {t("Processings:All")}
                  </Typography>
                </IconButton>
              </Box>
            </Box>
          )}
          <InfiniteLoader
            isRowLoaded={({ index }) => index < inputs.length}
            loadMoreRows={loadMoreRows}
            rowCount={totalCount ?? undefined}>
            {({ onRowsRendered, registerChild }) => (
              <List
                style={isOverflow ? { paddingRight: "10px", MozPaddingEnd: "0" } : undefined}
                onRowsRendered={onRowsRendered}
                ref={registerChild}
                width={WIDTH}
                height={HEIGHT}
                rowHeight={cache.current.rowHeight}
                rowCount={inputs.length}
                deferredMeasurementCache={cache.current}
                rowRenderer={({ key, index, style, parent }) => {
                  const inputImage = inputs[index];
                  const fileName = inputImage.fileMeta.name;
                  return (
                    <CellMeasurer cache={cache.current} parent={parent} columnIndex={0} rowIndex={index} key={key}>
                      <div style={{ ...style, paddingBottom: "5px" }}>
                        <Fade in>
                          <Paper sx={inputFileStyles} elevation={0}>
                            <Tooltip title={fileName}>
                              <Typography component="div" variant="body1" sx={fileNameStyles} fontWeight={300}>
                                {fileName}
                              </Typography>
                            </Tooltip>
                            <Typography variant="caption" fontWeight={300} sx={{ mr: 1, color: "#ADADAD" }}>
                              {formatBytes(inputImage.fileMeta.size)}
                            </Typography>
                            <Divider orientation="vertical" flexItem sx={{ borderColor: "secondary.main" }} />
                            {inputImage.type === "IMAGE" && (
                              <IconButton
                                sx={iconStyles()}
                                onClick={async () => await mapStore.addSinglePoint(inputImage.id, processing.id)}
                                disabled={pointsIsFetching === inputImage.id}>
                                {pointsIsFetching === inputImage.id && (
                                  <CircularProgress sx={{ position: "absolute" }} size="25px" />
                                )}
                                <MyLocationIcon />
                              </IconButton>
                            )}
                            <IconButton
                              sx={iconStyles()}
                              disabled={!inputImage.status.includes("SUCCESS")}
                              onClick={async () =>
                                await (processing &&
                                  fileStore.open(
                                    inputImage.subsystem,
                                    inputImage.storageProvider,
                                    processing.id,
                                    processing.acl,
                                    inputImage.storageMeta.key,
                                    processing.projectId
                                  ))
                              }>
                              <VisibilityOutlinedIcon />
                            </IconButton>
                          </Paper>
                        </Fade>
                      </div>
                    </CellMeasurer>
                  );
                }}
              />
            )}
          </InfiniteLoader>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              sx={{ mt: 1.5, px: 0.5 }}
              onClick={() => {
                processing?.type === Processings.VIPOINT
                  ? openVipointProcessingModal()
                  : processing && startProcessing(processing.id);
              }}
              disabled={getIsDisabled()}>
              <Typography variant="caption" sx={{ ml: 0.25, mr: 0.5 }}>
                {t(`Processings:${getButtonText(processing?.type === Processings.VIPOINT)}`)}
              </Typography>
              {ProcessingIsRunning && <CircularProgress size={24} sx={{ position: "absolute" }} />}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

const containerStyles = {
  position: "relative",
  mt: "10px",
  width: "100%",
  "& div::-webkit-scrollbar": {
    width: "5px",
  },
  "& div::-webkit-scrollbar-track": {
    bgcolor: "secondary.main",
    borderRadius: "5px",
  },
  "& div::-webkit-scrollbar-thumb": {
    bgcolor: "background.paper",
    borderRadius: "5px",
  },
  "& div": { scrollbarColor: "#333 #444", scrollbarWidth: "thin" },
} as const;

const fileNameStyles = {
  flex: 1,
  ml: 0.5,
  mr: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
} as const;

const iconStyles = (obj?: { borderRadius: string; padding: string }) => {
  return {
    ...obj!,
    ml: 1,
    bgcolor: "secondary.light",
    color: "text.primary",
    "& .MuiSvgIcon-root": {
      color: "text.primary",
      transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "&:hover": {
      bgcolor: "secondary.light",
      "& .MuiSvgIcon-root": {
        color: "primary.main",
      },
    },
    "&.Mui-disabled": {
      bgcolor: "secondary.main",
      "& .MuiSvgIcon-root": {
        opacity: 0.2,
      },
    },
  };
};

export default observer(Inputs);
