import React, { FC, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import OverflowTooltip from "components/shared/OverflowTooltip";

interface AffiliationInfoProps {
  parentTypeTitle: string; // cs, area, job
  title: string;
}

const AffiliationInfo: FC<AffiliationInfoProps> = ({ parentTypeTitle, title }) => {
  const titleRef = useRef<HTMLElement>(null);

  return (
    <Box display={"flex"}>
      <Typography variant="caption" fontWeight={"bold"}>
        {parentTypeTitle}:
      </Typography>
      <OverflowTooltip element={titleRef.current} title={title}>
        <Typography ref={titleRef} variant="caption" sx={{ pl: 1 }} noWrap>
          {title}
        </Typography>
      </OverflowTooltip>
    </Box>
  );
};

export default AffiliationInfo;
