import React, { FC, useCallback, useState } from "react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { useRootStore } from "hooks/useRootStore";
import { getHoveredTextStyles } from "constants/styles";
import { copyCoordinatesToClipboard } from "helpers/functions";
import { checkPointHasCoords, getPointCorrection } from "components/shared/Measurements/helpers";
import { MeasurementPoint } from "stores/measurementStore/types";
import { Popover, SxProps } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FileName } from "stores/fileStore/types";

function formatTimestamp(timestamp: number): string {
  console.log("timestamp", timestamp);
  const date = new Date(timestamp);
  const year = date.getUTCFullYear().toString().slice(-2);
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  return `${year}${month}${day}_${hours}${minutes}_`;
}

interface ActionButtonsProps {
  point?: MeasurementPoint;
}

const ActionButtons: FC<ActionButtonsProps> = observer(({ point }) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const {
    fileStore: { createDownloadLink, downloadFileFromURL, getFilesByQueryParams },
  } = useRootStore();
  const [isLoading, setIsLoading] = useState(false);

  if (!point) return null;

  const handleDownloadFile = async () => {
    setIsLoading(true);
    await getFilesByQueryParams(point.id, { type: FileName.FIX_POINT_CALIBRATION_PROTOCOL })
      .then(async response => {
        response.files.map(async file => {
          await createDownloadLink(file.foreignId, file.id).then(async file => {
            await downloadFileFromURL(
              file.url!,
              `${formatTimestamp(+file.createdAt)}${point.correction?.fixPointName}`
            );
          });
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Box sx={{ position: "relative" }}>
      {point.correction ? (
        <MoreHorizIcon
          onClick={e => {
            setAnchorEl(e.currentTarget);
          }}
          fontSize="small"
          sx={getHoveredTextStyles()}
        />
      ) : (
        <CopyIcon point={point} hoveredStyles={getHoveredTextStyles()} />
      )}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}>
        <Box
          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          onMouseLeave={() => setAnchorEl(null)}>
          <Zoom in style={{ transitionDelay: "100ms" }}>
            <Fab size="small" aria-label="edit" sx={{ m: 0.5, color: "background.default" }}>
              <CopyIcon point={point} />
            </Fab>
          </Zoom>
          <Zoom in style={{ transitionDelay: "200ms" }}>
            <Fab size="small" aria-label="edit" sx={{ m: 0.5, color: "background.default" }}>
              <FileDownloadIcon onClick={handleDownloadFile} fontSize="small" />
            </Fab>
          </Zoom>
        </Box>
      </Popover>
    </Box>
  );
});

export default ActionButtons;
interface CopyIconProps {
  point: MeasurementPoint;
  hoveredStyles?: SxProps;
}

const CopyIcon: React.FC<CopyIconProps> = ({ point, hoveredStyles }) => {
  const { uiStore } = useRootStore();

  const handleCopy = async (e: React.MouseEvent<SVGSVGElement>) => {
    if (checkPointHasCoords(point)) {
      const { x, y, z } = point;
      const dataToCopy = {
        x,
        y,
        z,
        ...getPointCorrection(point),
      };
      await copyCoordinatesToClipboard(e, dataToCopy, uiStore);
    }
  };
  return <ContentCopyOutlinedIcon onClick={handleCopy} sx={hoveredStyles} fontSize="small" />;
};
