import React, { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";

import { IFile } from "stores/fileStore/types";
import { Processings } from "stores/managerService/processingStore/types";

import { Loader } from "components/shared/Loader";
import Configuration from "components/ConstructionSite/Processings/Configuration";
import { useFetchWithLoading } from "hooks";
import { useRootStore } from "hooks/useRootStore";

import Outputs from "./List/Outputs";
import FileSelection from "./FileSelection";
import List from "./List";

const Files: FC = () => {
  const { fileStore, processingStore } = useRootStore();

  const { inputs } = fileStore;
  const { processing } = processingStore;

  const getAllFiles = async () => {
    if (processing?.type !== Processings.PIX4D_VIEW) {
      const withTotalCount = !!(processing?.config || processing?.type === Processings.PIX4D_DEPTH);
      await fileStore.getInputs(processing!.id, processing!.acl ,withTotalCount, true);
    }
  };

  const [fetchAllFilesWithLoading, isFilesLoading] = useFetchWithLoading(getAllFiles);
  useEffect(() => {
    fetchAllFilesWithLoading();
    return () => {
      fileStore.setInputs([] as IFile[]);
      fileStore.setOutputs(null);
      fileStore.clearFilesFromInputs();
      fileStore.setLastId(null);
      fileStore.setTotalCount(null);
      processingStore._setProcessing(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFilesLoading) {
    return <Loader />;
  }
  if (processing?.type === Processings.PIX4D_VIEW) {
    return <Outputs />;
  }

  if (
    (processing?.type === Processings.PIX4D_COMMON || processing?.type === Processings.PIX4D_GCP) &&
    !processing?.config
  ) {
    return <Configuration />;
  }

  if (!inputs.length) {
    return <FileSelection />;
  }

  return <List />;

};

export default observer(Files);
