const EMPTY_STRING = "";
const EMPTY_PATH_STRING = "/";

export const clientId = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;
export const protocol = process.env.REACT_APP_PROTOCOL;
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const pix4dBaseUrl = process.env.REACT_APP_PIX4D_CLOUD;

export enum Service {
  PROJECT = "projectService",
  VIPOINT = "vipointService",
  MEASUREMENT = "measurementService",
  EMAIL = "emailService",
  BILLING = "billingService",
  USER = "userService",
  DXF_SERVICE = "dxfService",
  INTEGRATION = 'integrationService'
}

export const getServiceUrl = (service: Service, path?: string): string => {
  const baseServiceUrl = `${protocol}//${baseUrl}/${service}`;

  if (path !== undefined && path !== EMPTY_STRING && path !== EMPTY_PATH_STRING) {
    return baseServiceUrl + `${path}`;
  }

  return baseServiceUrl;
};
