import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  IconButton,
  SxProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CredentialType } from "stores/integrationStore/types";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { observer } from "mobx-react-lite";
import { useFetch, useRootStore } from "hooks";
import { LoadingIndicatorOverlay } from "components/shared/LoadingIndicatorOverlay";
import Close from "@mui/icons-material/Close";
import { LOCALES, getSettingsLocale } from "../../locales";
import { Theme } from "@emotion/react";

type IntegrationDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const IntegrationDialog: React.FC<IntegrationDialogProps> = observer(({ open, onClose }) => {
  const {
    integrationStore: { createCredential },
    uiStore: { setSnackbarOpen },
  } = useRootStore();
  const [invokeCreateCredential, isLoading] = useFetch(createCredential);
  const { t } = useTranslation();
  const [type, setType] = useState<CredentialType>(CredentialType.PIX4D);
  const [name, setName] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [showErrorMessage, setErrorMesage] = useState(false);

  useEffect(() => {
    setErrorMesage(false);
  }, [clientId, name, clientSecret]);

  const resetInputs = () => {
    setName("");
    setClientId("");
    setClientSecret("");
    setType(CredentialType.PIX4D);
  };

  const handleCloseDialogWindow = () => {
    onClose();
    resetInputs();
  };

  const handleCreate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    invokeCreateCredential({ name, clientId, clientSecret, type })
      .then(() => {
        resetInputs();
        setSnackbarOpen(t(LOCALES.SUCCESSFULLY_CREATED), "success");
        handleCloseDialogWindow();
      })
      .catch(error => {
        setErrorMesage(true);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialogWindow}
      aria-labelledby="form-dialog-title"
      sx={{ "& .MuiDialog-paper": { backgroundColor: "background.default" } }}>
      <form onSubmit={handleCreate}>
        {isLoading && <LoadingIndicatorOverlay />}
        <DialogTitle textAlign="center" id="form-dialog-title">
          {t(LOCALES.ADD_INTEGRATION)}
        </DialogTitle>
        <IconButton onClick={handleCloseDialogWindow} sx={buttonStyles}>
          <Close />
        </IconButton>
        <DialogContent>
          <TextField
            onChange={e => setName(e.target.value)}
            value={name}
            autoFocus
            margin="dense"
            label={t(LOCALES.NAME)}
            type="text"
            fullWidth
            variant="outlined"
            required
            autoComplete="off"
          />
          <FormControl sx={{ my: 1.5 }} fullWidth required>
            <InputLabel id="type">{t(LOCALES.TYPE)}</InputLabel>
            <Select
              required
              value={type}
              labelId="type"
              label={t(LOCALES.TYPE)}
              onChange={e => setType(e.target.value as CredentialType)}
              IconComponent={KeyboardArrowDownRoundedIcon}>
              {Object.keys(CredentialType).map(type => (
                <MenuItem value={type} key={type}>
                  {t(getSettingsLocale(type))}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            required
            onChange={e => setClientId(e.target.value)}
            value={clientId}
            margin="dense"
            label={t(LOCALES.CLIENT_ID)}
            type="text"
            fullWidth
            variant="outlined"
            autoComplete="off"
          />
          <TextField
            onChange={e => setClientSecret(e.target.value)}
            value={clientSecret}
            margin="dense"
            label={t(LOCALES.CLIENT_SECRET)}
            type="text"
            fullWidth
            variant="outlined"
            required
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions sx={{ position: "relative" }}>
          {showErrorMessage && (
            <Typography color="primary.main" textAlign="center" position="absolute" top={-10} left={0} right={0}>
              {t(LOCALES.INCORRECT_INFO)}
            </Typography>
          )}
          <Box width="100%" display="flex" justifyContent="center" p={2}>
            <Button disabled={isLoading} color="secondary" variant="contained" fullWidth type="submit">
              {t("General:Create")}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
});

const buttonStyles: SxProps<Theme> = {
  color: "primary.contrastText",
  "&:hover": {
    color: "primary.main",
  },
  position: "absolute",
  top: 0,
  right: 0,
};
