import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { NoDataMessage } from "components/shared/NoDataMessage";
import { TabPanel } from "components/ConstructionSite/Area/Card/Tabs";
import { inputFileStyles, tabStylesWithIndicator, thumbStyles } from "constants/styles";
import { useFetchWithLoading } from "hooks";
import { useRootStore } from "hooks/useRootStore";

import { FormLabelContent } from "./CheckboxTabPanel/FormLabelContent";
import { CheckboxTabPanel } from "./CheckboxTabPanel";

export enum InputType {
  DXF = "DXF",
  CSV = "CSV",
}

export const ReuseFilesVipoint: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    fileStore: {
      pointCloudFilesForReuse,
      dxfFilesForReuse,
      csvFilesForReuse,
      getAllFilesForReuse,
      resetPointCloudFilesForReuse,
      setSelectedPointCloudForReuse,
    },
    processingStore: { processing },
  } = useRootStore();

  const [pointCloudInput, setPointCloudInput] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  const [fetchAllFilesForReuse, isLoading] = useFetchWithLoading(getAllFilesForReuse);

  useEffect(() => {
    if (processing) fetchAllFilesForReuse(processing.job);
    return () => {
      resetPointCloudFilesForReuse();
    };
  }, []);

  useEffect(() => {
    if (pointCloudInput) setSelectedPointCloudForReuse(pointCloudInput);
  }, [pointCloudInput]);

  return (
    <Box>
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          mt: 1.5,
          color: "#ADADAD",
        }}
        component="div">
        {t("Processings:SelectFiles")}
      </Typography>
      <Tabs
        value={tabIndex}
        onChange={(e, i) => setTabIndex(i)}
        aria-label="tabs"
        variant="fullWidth"
        sx={{ ...tabStylesWithIndicator, mb: 1 }}>
        <Tab label={t("Processings:PointCloud")} disableRipple />
        <Tab label={t("Processings:DXF")} disableRipple />
        <Tab label={t("Processings:CSV")} disableRipple />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <RadioGroup sx={formGroupStyles} value={pointCloudInput} onChange={e => setPointCloudInput(e.target.value)}>
          {isLoading ? (
            <CircularProgress size={35} sx={{ color: "text.primary", mt: 1.5 }} />
          ) : pointCloudFilesForReuse.length > 0 ? (
            pointCloudFilesForReuse.map(pc => (
              <FormControlLabel
                key={pc.id}
                sx={formControlLabelStyles}
                value={pc.id}
                control={<Radio />}
                label={<FormLabelContent file={pc} />}
              />
            ))
          ) : (
            <NoDataMessage message={t("Processings:NoFilesToChoose")} />
          )}
        </RadioGroup>
      </TabPanel>
      <CheckboxTabPanel
        tabIndex={tabIndex}
        index={1}
        isLoaded={isLoading}
        files={dxfFilesForReuse}
        inputType={InputType.DXF}
      />
      <CheckboxTabPanel
        tabIndex={tabIndex}
        index={2}
        isLoaded={isLoading}
        files={csvFilesForReuse}
        inputType={InputType.CSV}
      />
    </Box>
  );
});

export const formControlLabelStyles = { m: 0, mb: 0.5, ...inputFileStyles };
export const formGroupStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100px",
  maxHeight: "180px",
  overflowX: "auto",
  flexDirection: "row",
  width: "400px",
  ...thumbStyles,
};
