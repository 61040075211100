import React, { useEffect, useState } from "react";
import { MonthSelect } from "./Selectors/MonthSelect";
import { QuarterSelect } from "./Selectors/QuarterSelect";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Paper } from "@mui/material";
import { useFetchWithLoading } from "hooks";
import { useRootStore } from "hooks/useRootStore";
import { truncateText } from "helpers/functions";
import { thumbStyles } from "constants/styles";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { GetTeamBy } from "stores/managerService/teamStore/types";
import { MetricSelector } from "./Selectors/MetricSelector";
import { Loader } from "components/shared/Loader";
import { UserSelector } from "./Selectors/UserSelector";
import { IConstructionSite } from "stores/managerService/constructionSiteStore/types";
import { useFormattedDateRange } from "hooks/useFormatDateRange";

export interface DateRange {
  fromDate?: number;
  toDate?: number;
}
enum DateRangeOption {
  CurrentMonth = "CurrentMonth",
  PreviousMonths = "PreviousMonths",
  PreviousQuarters = "PreviousQuarters",
}

export const Metrics = observer(() => {
  const [activeMenu, setActiveMenu] = useState<DateRangeOption>(DateRangeOption.CurrentMonth);
  const {
    constructionSiteStore: { getAll, filteredCS },
    teamStore: { getCSTeamMembers, constructionSiteTeamMembers },
    metricStore: { setQueryParams, totalCosts, isBillingInfoFetching },
  } = useRootStore();
  const [invokeGetAllCS, isLoadingGetAllCS, isLoadedGetAllCS] = useFetchWithLoading(getAll);
  const [invokeGetTeam, isLoadingGetTeam] = useFetchWithLoading(getCSTeamMembers);
  const [selectedCs, setCS] = useState<IConstructionSite | null>(null);
  const [startAndEndDateInMilliseconds, setStartAndEndDateInMilliseconds] = useState<DateRange | null>(null);
  const formatDateRange = useFormattedDateRange();
  const { CurrentMonth, PreviousMonths, PreviousQuarters } = DateRangeOption;
  useEffect(() => {
    invokeGetAllCS();
  }, []);

  useEffect(() => {
    if (selectedCs) {
      invokeGetTeam(GetTeamBy.ACL, selectedCs.acl);
      setQueryParams({ constructionSiteId: selectedCs.id });
    }
  }, [selectedCs]);

  useEffect(() => {
    setQueryParams({
      fromDate: startAndEndDateInMilliseconds?.fromDate,
      toDate: startAndEndDateInMilliseconds?.toDate,
    });
  }, [startAndEndDateInMilliseconds]);

  const { t } = useTranslation();

  if (isLoadingGetAllCS || !isLoadedGetAllCS) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2} sx={{ height: "100%", marginTop: 0 }}>
      <Grid item xs={2.5}>
        <Box sx={wrapper}>
          <Typography
            style={activeMenu === CurrentMonth ? activeTextStyle : textStyle}
            onClick={() => {
              setActiveMenu(CurrentMonth), setStartAndEndDateInMilliseconds(null);
            }}>
            {t(`Metrics:${CurrentMonth}`)}
          </Typography>
        </Box>
        <Box sx={wrapper}>
          <Typography
            style={activeMenu === PreviousMonths ? activeTextStyle : textStyle}
            onClick={() => setActiveMenu(PreviousMonths)}>
            {t(`Metrics:${PreviousMonths}`)}
          </Typography>
          {activeMenu === PreviousMonths && (
            <MonthSelect setStartAndEndDateInMilliseconds={setStartAndEndDateInMilliseconds} />
          )}
        </Box>
        <Box sx={wrapper}>
          <Typography
            style={activeMenu === PreviousQuarters ? activeTextStyle : textStyle}
            onClick={() => setActiveMenu(PreviousQuarters)}>
            {t(`Metrics:${PreviousQuarters}`)}
          </Typography>
          {activeMenu === PreviousQuarters && (
            <QuarterSelect setStartAndEndDateInMilliseconds={setStartAndEndDateInMilliseconds} />
          )}
        </Box>
      </Grid>
      <Grid item xs={9.5} sx={{ gridTemplateRows: "1fr 12fr" }}>
        <Grid container item direction="row" sx={{ paddingBootom: 1, marginBottom: 1 }}>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              width: "500px",
              overflow: "auto",
              ...thumbStyles,
              paddingBottom: 1,
              "&::-webkit-scrollbar": {
                height: "5px",
              },
            }}>
            {filteredCS?.map(cs => (
              <Typography
                key={cs.id}
                onClick={() => setCS(cs)}
                sx={{
                  backgroundColor: (selectedCs?.id === cs.id && "text.primary") || "background.paper",
                  fontSize: 14,
                  borderRadius: 10,
                  minWidth: "200px",
                  padding: 1,
                  mr: 1,
                  textAlign: "center",
                  cursor: "pointer",
                  color: (selectedCs?.id === cs.id && "background.paper") || "text.primary",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {truncateText(cs.title, 23)}
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid container item xs={11} sx={{ height: "100%" }}>
          <Paper sx={{ display: "flex", flex: 1 }}>
            <Box sx={{ flex: 4, borderRight: "1px solid black", padding: 1 }}>
              <MetricSelector />
              {isBillingInfoFetching ? (
                <Loader />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 5,
                  }}>
                  {!selectedCs ? (
                    <Typography>{t("Metrics:SelectCS")}</Typography>
                  ) : (
                    <Box
                      sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                      <Typography>{t(`Metrics:Costs`)}</Typography>
                      <Typography margin={1} variant="h5" color="textPrimary">{`${totalCosts} €`}</Typography>
                      <Typography variant="body1" color="textSecondary">
                        {formatDateRange(startAndEndDateInMilliseconds || {})}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Box sx={{ flex: 3, padding: 1 }}>
              {isLoadingGetTeam ? (
                <Loader />
              ) : (
                constructionSiteTeamMembers && <UserSelector users={constructionSiteTeamMembers} />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
});

const wrapper = { marginBottom: 2, marginLeft: 2 };
const textStyle = {
  fontSize: "16px",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
  paddingLeft: "8px",
  borderLeft: "3px solid transparent",
  color: "#EFEFEF",
};

const activeTextStyle = {
  color: "#FFC601",
  fontSize: "16px",
  cursor: "pointer",
  paddingLeft: "8px",
  borderLeft: "3px solid #FFC601",
  "&:hover": {
    textDecoration: "underline",
  },
};
