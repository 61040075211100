import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Graphic from "@arcgis/core/Graphic";
import { Box, Paper, Popover, SxProps, Theme, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import { GraphicId } from "stores/mapStore/types";
import { Measurement, MeasurementCorrection, MeasurementPoint, MeasurementType } from "stores/measurementStore/types";

import { useRootStore } from "hooks/useRootStore";

import { getMeasurementItemInfoByTypeFunctions } from "../../helpers";
import { getDateString } from "helpers/functions";
import TaskMask from "components/Tasks/Card/TaskMask";
import ActionButtons from "./ActionButtons";
import { useFetchWithLoading } from "hooks";
import { LoadingIndicatorOverlay } from "components/shared/LoadingIndicatorOverlay";
import { Loader } from "components/shared/Loader";

interface MeasurementItemProps {
  measurementType: MeasurementType;
  measurement: Measurement;
  selected: boolean;
  handleSelectMeasurementForExport: (value: string) => void;
  exportMode: boolean;
}

const MeasurementItem: FC<MeasurementItemProps> = ({
  measurementType,
  measurement,
  handleSelectMeasurementForExport,
  selected,
  exportMode,
}) => {
  const { t } = useTranslation();
  const {
    measurementStore: { getAllPoints, getMeasurementGraphicByType },
    mapStore: {
      utils: { removeGraphicsByGraphicType, zoomToGeometry },
      addGraphicAndUpdate,
    },
    uiStore,
  } = useRootStore();

  const [points, setPoints] = useState<MeasurementPoint[] | null>(null);
  const [popupContent, setPopupContent] = React.useState<MeasurementCorrection>();
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const [invokeGetAllPoints, isLoadingAllPoints] = useFetchWithLoading(getAllPoints);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOpenPopover = (
    event: React.MouseEvent<SVGSVGElement, globalThis.MouseEvent>,
    content: MeasurementCorrection
  ) => {
    setAnchorEl(event.currentTarget);
    setPopupContent(content);
  };

  useEffect(() => {
    const fetchPoints = async () => {
      const points = await invokeGetAllPoints(measurement.id);
      setPoints(points);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchPoints();
  }, [invokeGetAllPoints, measurement.id]);

  const [measurementGraphic, setMeasurementGraphic] = useState<Graphic | null>(null);

  useEffect(() => {
    if (points?.length) {
      setMeasurementGraphic(getMeasurementGraphicByType(measurementType, points));
    }
  }, [getMeasurementGraphicByType, measurementType, points]);

  const handleSelect = useCallback(async () => {
    if (!measurementGraphic) {
      return;
    }
    handleSelectMeasurementForExport(measurement.id);
    removeGraphicsByGraphicType(GraphicId.measurement);
    await addGraphicAndUpdate(measurementGraphic);
    await zoomToGeometry(measurementGraphic.geometry);
  }, [addGraphicAndUpdate, measurementGraphic, removeGraphicsByGraphicType, zoomToGeometry]);

  const measurementInfos = useMemo(() => {
    if (!points?.length || !measurementGraphic) {
      return null;
    }

    return getMeasurementItemInfoByTypeFunctions(uiStore, handleOpenPopover)[measurementType](
      points,
      measurementGraphic.geometry
    );
  }, [measurementGraphic, measurementType, points, uiStore]);

  if (!points?.length) {
    return null;
  }

  return (
    <Paper onClick={handleSelect} sx={containerStyles}>
      {exportMode && <TaskMask selected={selected} />}
      <Grid container padding={1} borderBottom={1} sx={{ borderBottomColor: "secondary.light" }}>
        <Grid item xs={6}>
          <Typography variant="body1">{measurement.title}</Typography>
        </Grid>
        <Grid item xs={6} alignItems="flex-end">
          <Typography textAlign="end" variant="body2" sx={{ color: "text.secondary" }}>
            {t("Measurements:Date", { createdAt: getDateString(measurement.createdAt) })}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        {measurementInfos?.map(({ title, value, unit, pointInfo, correction, infoIcon, code }) => {
          if (title) {
            return (
              <Grid item xs={4} padding={1} key={title} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" flexGrow={1} fontSize={14}>
                  {t(title)}: {value} {unit && t(unit)}
                </Typography>
                <ActionButtons point={pointInfo} />
              </Grid>
            );
          } else if (code) {
            return (
              <Paper sx={codeStyles}>
                <Box sx={innerCodeBoxStyles}>
                  <Typography variant="body2">{t(code)}</Typography>
                  <Typography variant="body2" ml={1}>
                    {value}
                  </Typography>
                </Box>
              </Paper>
            );
          } else if (correction) {
            return (
              <Paper sx={paperStyles}>
                <Box display="flex" alignItems="center" flex={1}>
                  <Box sx={innerBoxStyles}>
                    <Typography variant="body2">{t(correction)}</Typography>
                  </Box>
                  <Typography variant="body2" ml={2}>
                    {value}
                  </Typography>
                </Box>
                <Box>{infoIcon}</Box>
              </Paper>
            );
          }
        })}
      </Grid>
      <Popover
        open={!!anchorEl && !!popupContent}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        <Box sx={{ p: 2 }}>
          {popupContent &&
            Object.entries(popupContent).map(([key, value]) => (
              <Typography sx={{ borderBottom: 1, borderColor: "secondary.light" }} key={key}>{`${t(
                `Measurements:${key}`
              )}: ${value}`}</Typography>
            ))}
        </Box>
      </Popover>
    </Paper>
  );
};

const containerStyles: SxProps<Theme> = {
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
};

const paperStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flex: 1,
  padding: 1,
  backgroundColor: "secondary.light",
};

const codeStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: 1,
  pl: 0,
};

const innerBoxStyles = {
  border: 1,
  borderColor: "primary.main",
  borderRadius: 1,
  paddingX: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "primary.main",
  paddingY: 0.5,
};

const innerCodeBoxStyles = {
  paddingX: 1,
  display: "flex",
  color: "primary.main",
};

export default MeasurementItem;
