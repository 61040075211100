import axios from "axios";

import { protocol, baseUrl, Service } from "configs/servicesConfig";
import type { CredentialsResponse, CredentialsRequest, CreateCredentialResponse } from "./types";

const { INTEGRATION } = Service;

export const integrationService = {
  async fetchCredentialsList(): Promise<CredentialsResponse> {
    const response = await axios.get<CredentialsResponse>(`${protocol}//${baseUrl}/${INTEGRATION}`);
    return response.data;
  },
  async fetchCredentialById(id: string): Promise<CredentialsResponse> {
    const { data } = await axios.get<CredentialsResponse>(`${protocol}//${baseUrl}/${INTEGRATION}${id}`);
    return data;
  },
  async createCredential(requestBody: CredentialsRequest): Promise<CreateCredentialResponse> {
    const { data } = await axios.post<CreateCredentialResponse>(`${protocol}//${baseUrl}/${INTEGRATION}`, requestBody);
    return data;
  },
};
