import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { matchPath } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ListItemButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { IConstructionSite } from "stores/managerService/constructionSiteStore/types";

import { LOCALES } from "constants/locales";
import { SIDE_BAR_DISPLAY, SIDE_BAR_WIDTH } from "constants/styles";
import { useRootStore } from "hooks/useRootStore";
import { absolutePath, PATHS } from "router/paths";

import BlurBlank from "./BlurBlank";
import ExpandButton from "./ExpandButton";
import LanguageSelector from "./LanguageSelector";

interface MenuItem {
  icon: React.ReactNode;
  title: string;
  route: string;
  childrenRoutes?: string[];
}

const Sidebar: FC = () => {
  const { t } = useTranslation();
  const {
    authStore,
    constructionSiteStore: { _setConstructionSite },
  } = useRootStore();

  const navigate = useNavigate();
  const location = useLocation();

  const { newUser, firstName, lastName, initials, avatar } = authStore;

  const menuItems = useMemo<MenuItem[]>(
    () => [
      {
        icon: <AccessTimeIcon />,
        title: t(LOCALES.SIDEBAR.TASKS),
        route: PATHS.TASKS.INDEX,
      },
      {
        icon: <GroupWorkOutlinedIcon />,
        title: t(LOCALES.SIDEBAR.TEAMS),
        route: PATHS.TEAMS,
      },
      {
        icon: <BuildOutlinedIcon />,
        title: t(LOCALES.SIDEBAR.CONSTRUCTION_SITE),
        route: PATHS.HOME.INDEX,
        childrenRoutes: Object.values(PATHS.HOME).map(path => {
          if (typeof path === "string") {
            return path;
          }

          return path();
        }),
      },
      {
        icon: <SettingsOutlinedIcon />,
        title: t(LOCALES.SIDEBAR.SETTINGS),
        route: PATHS.SETTINGS.INDEX,
      },
      {
        icon: <QueryStatsIcon />,
        title: t(LOCALES.SIDEBAR.METRICS),
        route: PATHS.METRICS,
      },
      {
        icon: <HelpOutlineIcon />,
        title: t(LOCALES.SIDEBAR.HELP),
        route: PATHS.HELP,
      },
    ],
    [t]
  );

  const isSelected = useCallback(
    (i: MenuItem) => {
      return !!matchPath(i.route, location.pathname) || i.childrenRoutes?.some(r => matchPath(r, location.pathname));
    },
    [location.pathname]
  );

  const handleClick = useCallback(
    async (route: string) => {
      if (route === "help") {
        window.open("https://support.vigram.com", "_blank");
        return;
      }

      navigate(absolutePath(route));
      if (route === PATHS.HOME.INDEX) {
        await _setConstructionSite({} as IConstructionSite);
      }
    },
    [_setConstructionSite, navigate]
  );

  const [open, setOpen] = useState(true);

  const links = [
    { text: "Imprint", url: "" },
    { text: "Data protection", url: "" },
    { text: "AGBs", url: "https://test.zone.vitest.ninja/AGB-vigram_17122021.pdf" },
  ];
  return (
    <>
      <ExpandButton open={open} disabled={newUser} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} />
      <Drawer
        open={open}
        variant="persistent"
        sx={drawerStyles}
        PaperProps={{ elevation: 2, sx: { overflow: "visible" } }}>
        <Box sx={containerStyles}>
          <Typography component="h1" variant="h4" sx={{ my: 3 }}>
            viZone
          </Typography>
          <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item xs={12} sx={{ pb: 1 }}>
              <Paper sx={profileWrapperStyles}>
                <IconButton sx={logoutIconStyles} onClick={async () => await authStore.logout()}>
                  <Tooltip title={t("Auth:Logout")}>
                    <LogoutRoundedIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
                <Avatar
                  src={avatar.source ? avatar.source : undefined}
                  key={avatar.key}
                  sx={{ height: "52px", width: "52px" }}>
                  {initials}
                </Avatar>
                <Typography variant="subtitle2" component="div" sx={nameStyles}>
                  {firstName} {lastName}
                </Typography>
              </Paper>
            </Grid>
            {menuItems.map((menuItem, index) => (
              <Grid key={index} item xs={index === 2 ? 12 : 6}>
                <Paper elevation={1} onClick={() => handleClick(menuItem.route)}>
                  <ListItemButton component="div" selected={isSelected(menuItem)} sx={itemStyles}>
                    {menuItem.icon}
                    <Typography variant="subtitle1" component="div">
                      {menuItem.title}
                    </Typography>
                  </ListItemButton>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <LanguageSelector displayFlag={true} />
        </Box>
        {newUser && <BlurBlank />}
        <Box sx={{ flexDirection: "column", alignItems: "center", textAlign: "center" }}>
          <Typography variant="body2" textAlign="center" sx={{ cursor: "pointer" }}>
            2023 Vigram GmbH
          </Typography>
          {links.map(link => (
            <Typography
              key={link.text}
              onClick={() => {
                link.url && window.open(link.url, "_blank");
              }}
              sx={{ marginRight: 1, cursor: "pointer" }}
              variant="caption"
              role="button"
              tabIndex={0}>
              {link.text}
            </Typography>
          ))}
        </Box>
      </Drawer>
    </>
  );
};

const drawerStyles = {
  zIndex: 30,
  display: SIDE_BAR_DISPLAY,
  position: "relative",
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    bgcolor: "background.default",
    border: "none",
    scrollbarWidth: "none",
    width: SIDE_BAR_WIDTH,
  },
  "& .MuiDrawer-paper::-webkit-scrollbar": {
    width: 0,
  },
};

const containerStyles = {
  px: 2,
  pb: 2,
  background: "url('/images/sidebar.svg') top left no-repeat",
  overflowY: "auto",

  scrollbarColor: "#666 #333",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    bgcolor: "secondary.main",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    bgcolor: "background.paper",
    borderRadius: "5px",
  },
};

const profileWrapperStyles = {
  position: "relative",
  height: 100,
  display: "flex",
  alignItems: "center",
  px: 1.5,
} as const;

const logoutIconStyles = {
  position: "absolute",
  top: "5px",
  right: "5px",
  color: "text.primary",
} as const;

const nameStyles = {
  ml: 1,
  textOverflow: "ellipsis",
  overflow: "hidden",
};

const itemStyles = {
  height: "100px",
  justifyContent: "center",
  flexDirection: "column",
  borderRadius: "10px",
  transition: "color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": {
    color: "primary.main",
    cursor: "pointer",
  },
  "&.Mui-selected": {
    background: "transparent",
    color: "primary.main",
  },
} as const;

export default observer(Sidebar);
