import { makeAutoObservable } from "mobx";
import RootStore from "stores/rootStore";
import { integrationService } from "./service";
import { Credentials, CredentialsRequest } from "./types";
import { ResponseStatus } from "types";

export class IntegrationStore {
  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  public credentials = [] as Credentials[];

  public readonly setCredentials = (value: Credentials[]) => {
    this.credentials = value;
  };

  public readonly getCredentialsList = async () => {
    const response = await integrationService.fetchCredentialsList();
    if (response.status === ResponseStatus.OK) {
      console.log("getAllCredentials", response.payload);
      this.setCredentials(response.payload);
    } else {
      console.error("Failed to load credentials:", response.payload);
      this.setCredentials([]);
    }
  };

  public readonly createCredential = async (body: CredentialsRequest) => {
    try {
      const response = await integrationService.createCredential(body);
      if (response.status === ResponseStatus.OK) this.setCredentials([...this.credentials, response.payload]);
    } catch (error) {
      throw error;
    }
  };
}
