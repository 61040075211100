import { FileExt, FileName, FileType, SubSystem, SubType } from "./types";

export const FILE_TYPES: Record<FileName, FileType> = {
  ORTHOMOSAIC: {
    name: FileName.ORTHOMOSAIC,
    ext: [FileExt.tiff, FileExt.tif],
    subsystem: SubSystem.PIX4D,
    subtype: SubType.OUTPUT,
  },
  DSM: { name: FileName.DSM, ext: [FileExt.tiff, FileExt.tif], subsystem: SubSystem.PIX4D, subtype: SubType.OUTPUT },
  POINT_CLOUD: {
    name: FileName.POINT_CLOUD,
    ext: FileExt.las,
    subsystem: SubSystem.PIX4D,
    subtype: SubType.OUTPUT,
  },
  VIPOINT_POINT_CLOUD: {
    name: FileName.POINT_CLOUD,
    ext: FileExt.las,
    subsystem: SubSystem.VIGRAM,
    subtype: SubType.INPUT,
  },
  MESH_OBJ: {
    name: FileName.MESH_OBJ,
    ext: FileExt.obj,
    subsystem: SubSystem.PIX4D,
    subtype: SubType.OUTPUT,
  },
  MESH_MTL: {
    name: FileName.MESH_MTL,
    ext: FileExt.mtl,
    subsystem: SubSystem.PIX4D,
    subtype: SubType.OUTPUT,
  },
  MESH_TEXTURE_JPG: {
    name: FileName.MESH_TEXTURE_JPG,
    ext: [FileExt.jpg, FileExt.jpeg],
    subsystem: SubSystem.PIX4D,
    subtype: SubType.OUTPUT,
  },
  MESH_OFFSET_XYZ: {
    name: FileName.MESH_OFFSET_XYZ,
    ext: FileExt.xyz,
    subsystem: SubSystem.PIX4D,
    subtype: SubType.OUTPUT,
  },
  NDVI: { name: FileName.NDVI, ext: FileExt.tiff, subsystem: SubSystem.PIX4D, subtype: SubType.OUTPUT },
  IMAGE: {
    name: FileName.IMAGE,
    ext: [FileExt.jpg, FileExt.jpeg],
    subsystem: SubSystem.PIX4D,
    subtype: SubType.INPUT,
  },
  DEPTHMAP: {
    name: FileName.DEPTHMAP,
    ext: FileExt.tiff,
    subsystem: SubSystem.PIX4D,
    subtype: SubType.INPUT,
  },
  CONFIDENCE: {
    name: FileName.CONFIDENCE,
    ext: FileExt.tiff,
    subsystem: SubSystem.PIX4D,
    subtype: SubType.INPUT,
  },
  GCP: { name: FileName.GCP, ext: FileExt.csv, subsystem: SubSystem.VIGRAM, subtype: SubType.INPUT },
  VP_CSV: { name: FileName.VP_CSV, ext: FileExt.csv, subsystem: SubSystem.VIGRAM, subtype: SubType.INPUT },
  TEMPLATE: {
    name: FileName.TEMPLATE,
    ext: FileExt.tmpl,
    subsystem: SubSystem.PIX4D,
    subtype: SubType.INPUT,
  },
  DXF: {
    name: FileName.DXF,
    ext: FileExt.dxf,
    subsystem: SubSystem.VIGRAM,
    subtype: SubType.INPUT,
  },
  DXF_OUTPUT: {
    name: FileName.DXF,
    ext: FileExt.dxf,
    subsystem: SubSystem.VIGRAM,
    subtype: SubType.OUTPUT,
  },
  DOCUMENT: {
    name: FileName.DOCUMENT,
    ext: [
      FileExt.pdf,
      FileExt.doc,
      FileExt.docx,
      FileExt.xls,
      FileExt.xlsx,
      FileExt.ppt,
      FileExt.pptx,
      FileExt.kml,
      FileExt.zip,
      FileExt.png,
      FileExt.jpeg,
      FileExt.jpg,
      FileExt.geojson,
      FileExt.dxf,
      FileExt.dwg,
      FileExt.xml,
    ],
    subsystem: SubSystem.VIGRAM,
    subtype: SubType.OUTPUT,
  },
  MESH_OBJ_ZIP: {
    name: FileName.MESH_OBJ_ZIP,
    ext: FileExt.zip,
    subsystem: SubSystem.PIX4D,
    subtype: SubType.OUTPUT,
  },
  IFC: { name: FileName.IFC, ext: FileExt.ifc, subsystem: SubSystem.PIX4D, subtype: SubType.OUTPUT },
  AGISOFT_IMAGE: {
    name: FileName.IMAGE,
    ext: [FileExt.jpg, FileExt.jpeg],
    subsystem: SubSystem.VIGRAM,
    subtype: SubType.INPUT,
  },
  FIX_POINT_CALIBRATION_PROTOCOL: {
    name: FileName.FIX_POINT_CALIBRATION_PROTOCOL,
    ext: FileExt.txt,
    subsystem: SubSystem.VIGRAM,
    subtype: SubType.OUTPUT,
  },
  MEASUREMENT_IMPORT: {
    name: FileName.MEASUREMENT_IMPORT,
    ext: FileExt.dxf,
    subsystem: SubSystem.VIGRAM,
    subtype: SubType.INPUT,
  },
};

export enum FileSize {
  REGISTERED_FILES_BATCH_SIZE = 500,
  UPLOAD_BATCH_SIZE = 3,
}
