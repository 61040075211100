import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { MetricKey } from "stores/metricStore/types";
import { useRootStore } from "hooks/useRootStore";
import { useTranslation } from "react-i18next";

const ALL_METRICS = "ALL_METRICS";

type SelectedMetric = MetricKey | typeof ALL_METRICS;

export const MetricSelector: React.FC = () => {
  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>(ALL_METRICS);
  const {
    metricStore: { setQueryParams },
  } = useRootStore();
  const { t } = useTranslation();

  const handleMetricChange = (event: SelectChangeEvent<SelectedMetric>) => {
    const {
      target: { value },
    } = event;

    setSelectedMetric(value as MetricKey);
  };

  useEffect(() => {
    const svalue = selectedMetric === ALL_METRICS ? null : (selectedMetric as MetricKey);
    setQueryParams({ metricKey: svalue });
  }, [selectedMetric]);

  return (
    <FormControl variant="outlined" size="small" fullWidth sx={{ paddingRight: 2, paddingLeft: 2 }}>
      <Select
        sx={{
          fontSize: 14,
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "#ADADAD" },
        }}
        value={selectedMetric}
        onChange={handleMetricChange}>
        <MenuItem sx={{ fontSize: 14 }} value={ALL_METRICS}>
          {t(`Metrics:${ALL_METRICS}`)}
        </MenuItem>
        {Object.entries(MetricKey).map(([key, value]) => (
          <MenuItem sx={{ fontSize: 14 }} key={key} value={value}>
            {t(`Metrics:${value}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
