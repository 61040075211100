import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import { DisplayOptions } from "components/shared/DisplayOptions";
import { Loader } from "components/shared/Loader";
import { NoDataMessage } from "components/shared/NoDataMessage";
import useFetchConstructionSiteAndAreas from "hooks/manager/useFetchConstructionSiteAndAreas";
import { useRootStore } from "hooks/useRootStore";

import EditMode from "../EditMode";

import CreateButton from "./CreateButton";
import CreateMode from "./CreateMode";
import EditModeA from "./EditMode";
import List from "./List";

const Areas: FC = observer(() => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    areaStore,
    constructionSiteStore,
    uiStore: { setIsSearchFieldVisible },
  } = useRootStore();
  const { constructionSite, editableConstructionSite } = constructionSiteStore;
  const { areas, createMode, editMode, filterAreas } = areaStore;

  const [isLoadingInProgress] = useFetchConstructionSiteAndAreas(id);

  const inUpdatingProcess = createMode || editMode || editableConstructionSite;

  useEffect(() => {
    setIsSearchFieldVisible(false);
    filterAreas("");
  }, [filterAreas, setIsSearchFieldVisible]);

  return (
    <Box sx={wrapperStyles}>
      <Typography variant="subtitle2" textAlign="center" component="div" sx={{ mb: 1 }}>
        {t("Area:Areas")}
      </Typography>
      <Divider sx={{ mx: -1 }} />
      {createMode && <CreateMode constructionSiteId={constructionSite.id} />}
      {editMode && <EditModeA constructionSiteId={constructionSite.id} />}
      {editableConstructionSite && <EditMode />}
      {!inUpdatingProcess && (
        <DisplayOptions>
          <CreateButton />
        </DisplayOptions>
      )}
      {!(isLoadingInProgress || inUpdatingProcess) && (
        <>{areas.length ? <List /> : <NoDataMessage message={t("Area:NoAreas")} />}</>
      )}
      {isLoadingInProgress && <Loader />}
    </Box>
  );
});

const wrapperStyles = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  overflow: "hidden",
};

export default Areas;
