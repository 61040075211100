import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TooltipRenderProps } from "react-joyride";
import { Box, Button, Paper, SxProps, Theme, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import { ErrorI18KeyAndOptions } from "stores/managerService/helpers/checkGraphics";
import { onboardingStore } from "stores/onboardingStore";

import CloseIconButton from "components/shared/CloseIconButton";
import { Loader } from "components/shared/Loader";
import { getAppTourExtendedSteps } from "components/Onboarding/steps";
import { useFetchWithLoading } from "../../hooks";

const Tooltip: FC<TooltipRenderProps> = ({ step, index, closeProps, primaryProps, tooltipProps, isLastStep }) => {
  const { t } = useTranslation();
  const { constructionSiteStore } = useRootStore();

  const currentStep = useMemo(() => getAppTourExtendedSteps(constructionSiteStore)[index], [index]);

  useEffect(() => currentStep.callback?.(), [currentStep]);
  useEffect(() => onboardingStore.setIsLastStep(isLastStep), [isLastStep]);

  const [isNextStepAllowed, setIsNextStepAllowed] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<ErrorI18KeyAndOptions>();

  const handleNextStep = async () => {
    const errorMessageAndOptions = await currentStep.checkBeforeNextStep?.();

    if (errorMessageAndOptions) {
      setIsNextStepAllowed(false);
      setErrorMessage(errorMessageAndOptions);
    } else {
      setIsNextStepAllowed(true);
      setErrorMessage(errorMessageAndOptions);
      onboardingStore.nextStep();
    }
  };
  const [fetchNextStepWithLoading, isNextStepHandlerInProgress] = useFetchWithLoading(handleNextStep);

  const handleClose = () => {
    onboardingStore.finishTour();
  };

  return (
    <Paper sx={paperContainerStyles} {...tooltipProps}>
      <CloseIconButton zeroHeight disabled={isNextStepHandlerInProgress} {...closeProps} onClick={handleClose} />
      <Box sx={{ marginTop: 1.5 }}>
        <Typography variant={"h4"}>{step.title}</Typography>
      </Box>
      <Box sx={bodyStyles}>
        <Typography>{typeof step.content === "string" ? t(step.content) : step.content}</Typography>
      </Box>
      {step.hideFooter || (
        <Box sx={getFooterStyles(isNextStepAllowed)}>
          {isNextStepAllowed || (
            <Typography variant={"caption"} color={"error"}>
              {errorMessage && t(...errorMessage)}
            </Typography>
          )}
          <Button
            {...primaryProps}
            onClick={fetchNextStepWithLoading}>
            {isLastStep && t("Finish")}
            {!isNextStepHandlerInProgress && !isLastStep && t("Next")}
            {!isLastStep && isNextStepHandlerInProgress && <Loader size={"sm"} />}
          </Button>
        </Box>
      )}
    </Paper>
  );
};

const paperContainerStyles: SxProps<Theme> = {
  display: "flex",
  flexFlow: "column",
  gap: 1,
  padding: 1,
  bgcolor: "background.default",
  maxWidth: 350,
  minWidth: 200,
  maxHeight: 400,
  minHeight: 150,
};

const bodyStyles: SxProps<Theme> = {
  justifySelf: "center",
  overflowY: "auto",
  textAlign: "center",
};

const getFooterStyles: (isNextStepAllowed: boolean) => SxProps<Theme> = isNextStepAllowed => ({
  width: "100%",
  display: "flex",
  justifyContent: isNextStepAllowed ? "flex-end" : "space-between",
  gap: 1,
});

export default observer(Tooltip);
